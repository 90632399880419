import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import navigationStyle from './navigation.style';

export default function NavBoxItem({ Icon, label, route }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return <ListItem disablePadding onClick={() => navigate(route)}>
        <ListItemButton selected={pathname == route} sx={navigationStyle.item}>
            <ListItemIcon><Icon /></ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </ListItemButton>
    </ListItem>;
};
