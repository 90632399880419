import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TextField } from 'mui-rff';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { Login } from 'mdi-material-ui';
import { Form } from 'react-final-form';
import authService from '../_core/services/authService';
import { dialogOpen } from '../components/dialog';

export default () => {
    const location = useLocation();
    const navigate = useNavigate();

    const onSubmitLogin = useCallback(async ({ username, password }) => {
        const response = await authService.loginWithCredentials(username, password);

        if (response.success) {
            const previousLocation = location?.state?.from?.pathname;
            if (previousLocation && previousLocation != location.pathname) {
                navigate(previousLocation);
            }
        } else {
            if (response.status == 403) {
                dialogOpen({ title: 'Login failed', content: 'Wrong password.' });
            }
            if (response.status == 404) {
                dialogOpen({ title: 'Login failed', content: 'User not found.' });
            }
        }
    }, [location.pathname]);

    const validateLogin = values => {
        let errors = {};
        if (!values.username) {
            errors.email = 'Email is required.';
        }
        if (!values.password) {
            errors.password = 'Password is required.';
        }
        return errors;
    }

    return <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
            <Paper elevation={4} sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <Typography variant="h4" component="h1" sx={{ pt: 2, pb: 4 }}>
                    Lifechanger Admin
                </Typography>

                <Form
                    onSubmit={onSubmitLogin}
                    validate={validateLogin}
                    render={({ handleSubmit, values }) => <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField label="Username" name="username" required={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Password" name="password" required={true} type="password" />
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                <Button type="submit" variant="contained" color="primary"
                                    size="large" startIcon={<Login />}>Login</Button>
                            </Grid>
                        </Grid>
                    </form>} />
            </Paper>
        </Grid>
    </Grid>;
};
