export default {
    getCSV: (csv) => {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';

        //provide the name for the CSV file to be downloaded  
        hiddenElement.download = 'Subscriptions.csv';
        hiddenElement.click(); 
    }
};
