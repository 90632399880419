import React from 'react';
import logger from '../../utils/logger';
import useBehaviorSubject from '../hooks/useBehaviorSubject';
import { loggedIn$ } from '../subjects';

const UserProfileContext = React.createContext();

// this is what you "connect" to the rest of the app.
// Need this to depend (optionally) on other contexts, entities, etc? Connect this component to anything.
export const UserProfileProvider = ({ children }) => {
    const profile = useBehaviorSubject(loggedIn$);

    return <UserProfileContext.Provider value={profile && profile.user}>
        {children}
    </UserProfileContext.Provider>;
};

// React Hooks API:
export const useUserProfile = () => {
    const context = React.useContext(UserProfileContext);
    if (context === undefined) {
        // user profile does not make sense to appear outside provider
        logger.error('useUserProfileContext must be used within a UserProfileProvider');
    }
    return context;
};
