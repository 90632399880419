import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { columns } from './feedback.config';
import LCTable from '../../../components/lcTable/lcTable';
import { openEditPopupAsync } from '../../../components/popup/editPopup';
import { dialogConfirmAsync, dialogOpenAsync } from '../../../components/dialog';
import FeedbackFilterForm from './feedbackFilterForm';
import feedbackService from '../../../_core/services/feedbackService';
import FeedbackForm from './feedbackForm';
import progress from '../../../components/modalProgress/progressController';
import dateUtils from '../../../utils/dateUtils';
import { ADMIN_USER } from '../../../_core/services/apiService';

// This is used in Edit popup header AND the Delete confirmation dialog
const RowDisplayName = ({ row }) => {
    const { createdBy } = row?.original || {};
    return createdBy ? <>{createdBy}</> : <strong>Unnamed</strong>;
}

export default function FeedbackPage() {
    const [refresh, setRefresh] = useState();

    const getData = useCallback(async (options) => {
        console.log('[FeedbackPage::getData]', options);

        const response = await feedbackService.getList(options);

        return response;
    }, []);

    const getReply = (reply) => {
        return {
            _id: uuidv4().replace('-', ''),
            text: reply,
            type: "Comment",
            createdAt: dateUtils.toServerString(),
            createdBy: ADMIN_USER,
            updatedAt: dateUtils.toServerString(),
            updatedBy: ADMIN_USER
        }
    }

    // onEditRow should just return the added row so the table can re-render it
    const onEditRow = useCallback(async row => {
        console.log('edit', row);
        let initialValues = row?.original || {},
            item = await openEditPopupAsync({
                title: <RowDisplayName row={row} />,
                Content: FeedbackForm,
                initialValues,
                props: { getReply },
            });
        console.log('response', item);
        if (item) {
            // TODO: actually update data. DO NOT AWAIT HERE but inside a wrapping call, and after completion trigger a refresh:
            item = await (async () => {
                item.history = [...(item.history || []), getReply(item.reply)];
                delete item.reply;
                item.updatedBy = ADMIN_USER;
                let result = await feedbackService.updateItem(item);
                if (result && result.ok) {
                    setRefresh(Math.random());
                    return result.data;
                } else {
                    logger.error('Edit user failed', result, item)
                    await dialogOpenAsync('There was an error editing feedback!');
                    return item;
                }
            })();
        }
        return item; // not await-ing above allows this return to make optimistic update
    }, []);

    // onAddRow should just return the added row so the table can render it
    const onAddRow = useCallback(async () => {
        const item = await openEditPopupAsync({
            Content: FeedbackForm,
        });
        console.log('response', item);
        if (item) {
            // TODO: actually insert data. DO NOT AWAIT HERE but inside a wrapping call, and after completion trigger a refresh:
            item = await (async () => {
                progress.show();
                const result = await feedbackService.addItem(item);
                progress.hide();
                if (result && result.ok) {
                    setRefresh(Math.random());
                    return result.data;
                } else {
                    logger.error('Add feedback failed', result, item)
                    await dialogOpenAsync('There was an error adding feedback!');
                }
            })();
        }
        return item; // not await-ing above allows this return to make optimistic update
    }, []);

    // onDeleteRow should just return true/false if deletion worked or not, so the table can delete the row
    const onDeleteRow = useCallback(async row => {
        console.log('delete', row);
        const item = row?.original || {};
        if (item.userId) {
            await dialogOpenAsync('This coupon cannot be deleted because is already used!');
            return;
        }
        const confirmed = await dialogConfirmAsync({
            title: 'Are you sure?',
            content: <>DELETING <RowDisplayName key={2} row={row} /></>,
        });
        if (confirmed) {
            // TODO: actually delete data. DO NOT AWAIT HERE but inside a wrapping call, and after completion OPTIONALLY trigger a refresh:
            (async () => {
                progress.show();
                let result = await feedbackService.deleteItem(item);
                progress.hide();
                if (result) {
                    // TODO: do we want to? we can save server request if we skip this and let user manually refresh after deleting one (OR MANY) item/s.
                    setRefresh(Math.random());
                }
                else {
                    logger.error('Delete user failed', result, item);
                    await dialogOpenAsync('There was an error deleting feedback!');
                }
            })();
        }

        return confirmed; // not await-ing above allows this return to make optimistic update
    }, []);

    return <LCTable columns={columns}
        FiltersForm={FeedbackFilterForm}
        getData={getData}
        onEditRow={onEditRow}
        refresh={refresh}
    //onAddRow={onAddRow}
    //onDeleteRow={onDeleteRow}
    />;
};
