const localforage = require('localforage');

const localDb = localforage.createInstance({
    driver: [localforage.INDEXEDDB, localforage.WEBSQL],
    name: 'LCAdmin',
    storeName: 'Db',
}), localSt = localforage.createInstance({
    driver: [localforage.LOCALSTORAGE],
    name: 'LCAdmin',
    storeName: 'Storage',
});

const storage = {
    get(...keys) {
        if (localSt) {
            if (keys.length > 1) {
                return Promise.all(keys.map(async key => await localSt.getItem(key)));
            } else {
                return localSt.getItem(keys[0]);
            }
        }
        else {
            console.warn('Local storage not accessible');
            return null;
        }
    },
    set(key, value) {
        if (localSt) {
            return localSt.setItem(key, value);
        }
        else {
            console.warn('Local storage not accessible');
        }
    },
    unset(...keys) {
        if (localSt) {
            if (keys.length > 1) {
                return Promise.all(keys.map(async key => await localSt.removeItem(key)));
            } else {
                return localSt.removeItem(keys[0]);
            }
        } else {
            console.warn('Local storage not accessible');
        }
    },
    query(key) {
        return localDb.getItem(key);
    },
    save(key, value) {
        return localDb.setItem(key, value);
    },
    remove(key) {
        return localDb.removeItem(key);
    },
};

export default storage;
