import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useUserProfile } from '../contexts/userProfileContext';
import { privateRoutes, publicRoutes } from './routes';

export const NoMatch = ({ isPublic }) => {
    const location = useLocation();

    const to = isPublic ? publicRoutes.landing : privateRoutes.home;
    const state = isPublic ? { from: location } : {};

    return location.pathname == to ? null : <Navigate replace to={to} state={state} />;
}

export const EmptyIndex = ({ path, isPublic }) => {
    const location = useLocation();

    const to = isPublic ? publicRoutes.landing : privateRoutes.home;
    const state = isPublic ? { from: location } : {};

    return location.pathname == path ? <Navigate replace to={to} state={state} /> : <Typography>Nothing here (<em>{location.pathname}</em> is accessible but has no content).</Typography>;
};

export const PrivateRoute = ({ permissions = [], children }) => {
    const perms = useMemo(() => {
        if (permissions && !Array.isArray(permissions)) {
            return [permissions];
        }
        return permissions;
    }, [permissions]);

    const canAccessRoute = useCurrentUserCheck(...perms);

    return canAccessRoute ? children : <Typography>You shouldn't be here. <Link to={privateRoutes.home}>Go Home.</Link></Typography>;
};

// TODO: move this away from here once we implement an actual permissions system.
// This is just a stub/demo for how it should work, but it should be relocated to where the permissions system is implemented, and imported from there.
const useCurrentUserCheck = (...permissions) => {
    const user = useUserProfile();

    if (permissions.length) {
        // TODO: check permissions! temporarily (for testing) we reject anything that requires a permission
        return false;
    }

    return true;
};
