import React, { useState } from 'react';
import Routes from '../routes/routes';
import Navigation from '../../components/navigation/navigation';
import PopupWrapper from '../../components/popup/popup';
import DialogWrapper from '../../components/dialog';
import { ModalProgressWrapper } from '../../components/modalProgress/modalProgress';
import { useUserProfile } from '../contexts/userProfileContext';
import { Box, useMediaQuery } from '@mui/material';
import { mqMediumDown, mqSmallUp } from '../../_core/constants/mediaQueries';
import Header from '../../components/header';

const drawerWidth = 256;

export default function ApplicationLayout() {
    const isLoggedIn = useUserProfile();

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenuOpen = () => setMenuOpen(!menuOpen);

    const renderMenuAsToggleDrawer = useMediaQuery(mqMediumDown);

    return <>
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            {isLoggedIn ? <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            >
                {renderMenuAsToggleDrawer
                    ? <Navigation
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="temporary"
                        open={menuOpen}
                        onClose={toggleMenuOpen}
                    />
                    : <Navigation
                        PaperProps={{ style: { width: drawerWidth } }}
                    />}
            </Box> : null}

            <Box sx={{
                flex: 1, display: 'flex', flexDirection: 'column', height: '100vh', width: '100%',
                '@media(min-width: 900px)': { width: 'calc(100% - 256px)' },
            }}>
                {isLoggedIn ? <Header onDrawerToggle={toggleMenuOpen} /> : null}
                <Box component="main" sx={{ flex: 1, p: 3, minHeight: 0, width: '100%' }}>
                    <Routes />
                </Box>
            </Box>
        </Box>
        <ModalProgressWrapper />
        <PopupWrapper />
        <DialogWrapper />
    </>;
};
