import React, { useCallback, useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import { Check, Close } from 'mdi-material-ui';
import { dialogConfirmAsync } from '../dialog';
import { popupClose, popupOpen, popupUpdate } from './popup';
import { merge } from 'lodash';

const InnerForm = ({ handleSubmit, pristine, Content, popupLevel, ...props }) => {
    const submittingRef = useRef(false);

    const canClose = useCallback(() => {
        if (submittingRef.current) {
            submittingRef.current = false;
            return true;
        }

        return pristine || dialogConfirmAsync({
            title: 'Are you sure?',
            content: 'Changes will be lost',
            okText: 'Close',
        });
    }, [pristine]);

    useEffect(() => {
        popupUpdate(popupLevel, { canClose });
    }, [canClose]);

    useEffect(() => {
        popupUpdate(popupLevel, {
            actions: [{
                icon: <Check />,
                text: 'Save',
                callback: () => {
                    submittingRef.current = true;
                    handleSubmit();
                },
            }, {
                icon: <Close />,
                text: 'Cancel',
                callback: () => popupClose(popupLevel),
            }]
        });
    }, []);

    return <Content {...{ pristine, ...props }} popupLevel={popupLevel} />;
};

const EditForm = ({ Content, initialValues, onSubmit, popupLevel, ...props }) => {
    return <Form initialValues={initialValues} onSubmit={onSubmit}>
        {formProps => {
            return <InnerForm {...formProps} {...props} Content={Content} popupLevel={popupLevel} />;
        }}
    </Form>;
};

export const openEditPopupAsync = ({
    Content,
    initialValues = {},
    title = 'Edit',
    ...popupProps
}) => new Promise(resolve => {
    popupOpen(merge({}, {
        Component: EditForm,
        props: {
            Content,
            initialValues,
            onSubmit: values => {
                popupClose();
                resolve(values);
            },
        },
        title,
        onClosed: resolve,
    }, popupProps));
});
