import { users } from './dummyData';
import dateUtils from '../../utils/dateUtils';
import apiService, { DATA_SOURCE_TYPE, getUrlEncodedValue } from './apiService';

const prospectsService = {
    endpoint: 'prospects',
    getList: async ({ pageIndex, pageSize, filters }) => {
        const listEndpoint = `${prospectsService.endpoint}/_find`,
            selector = {};

        if (filters.userType) {
            selector.userType = { '$eq': filters.userType }; // $gte = startsWith
        }
        if (filters.programme) {
            selector.programme = { "$regex": `(.*${filters.programme}.*)` };
        }
        if (filters.email) {
            selector.email = { "$regex": `(.*${filters.email}.*)` };
        }
        if (filters.name) {
            selector.name = { "$regex": `(.*${filters.name}.*)` };
        }
        if (filters.coupon) {
            selector.coupon = { "$regex": `(.*${filters.coupon}.*)` };
        }
        if (filters.userId) {
            selector.userId = { "$regex": `(.*${filters.userId}.*)` };
        }

        if (filters.endDate) {
            selector.endDate = {};
            if (filters.endDate.after) {
                selector.endDate.$gte = dateUtils.toServerString(filters.endDate.after);
            }
            if (filters.endDate.before) {
                selector.endDate.$lte = dateUtils.toServerString(filters.endDate.before);
            }
        }
        if (filters.createdAt) {
            selector.createdAt = {};
            if (filters.createdAt.after) {
                selector.createdAt.$gte = dateUtils.toServerString(filters.createdAt.after);
            }
            if (filters.createdAt.before) {
                selector.createdAt.$lte = dateUtils.toServerString(filters.createdAt.before);
            }
        }

        let payload = {
            selector,
            limit: pageSize,
            skip: pageIndex * pageSize,
            sort: [
                {
                    "createdAt": "desc"
                }
            ]
        },
            data = await apiService.post(listEndpoint, payload);

        if (data && data.docs) {
            data = data.docs;

            let total = data.length < pageSize ? data.length : pageSize * (pageIndex + 1) + 1;

            return {
                data,
                pagination: {
                    filtered: total, // filtered is what the table shows at the bottom (page count is computed based on this). It may be equal to total if no filters are applied, that's ok
                },
            };
        }

        // If you want to display a custom message, return this:
        // return {
        //     ok: false,
        //     message: 'Custom error message',
        // };
        // Otheriwse, standard fail message will be shown
    },
    addItem: async (prospect) => {
        let endpoint = `prospect/adminAdd?firstName=${getUrlEncodedValue(prospect.firstName)}&lastName=${getUrlEncodedValue(prospect.lastName)}&email=${getUrlEncodedValue(prospect.email)}&programme=${getUrlEncodedValue(prospect.programme)}&userType=${getUrlEncodedValue(prospect.userType)}&freePeriod=${prospect.freePeriod}`,
            response = await apiService.post(endpoint, prospect, DATA_SOURCE_TYPE.API);
        if (!response || !response.success) {
            response = {
                ok: false,
                message: "Could not add the prospect",
            }
            return response;
        }
        return {
            data: response.data,
            ok: true,
        };
    },
    addBatchItems: async (batchDetails) => {
        let endpoint = `prospect/adminAddBatch?email=${getUrlEncodedValue(batchDetails.email)}&programme=${getUrlEncodedValue(batchDetails.programme)}&userType=${getUrlEncodedValue(batchDetails.userType)}&qty=${batchDetails.qty}&freePeriod=${batchDetails.freePeriod}`,
            response = await apiService.post(endpoint, batchDetails, DATA_SOURCE_TYPE.API);
        if (!response || !response.success) {
            response = {
                ok: false,
                message: "Could not add the prospects batch",
            }
            return response;
        }
        return {
            data: response.data,
            ok: true,
        };
    },
    updateItem: async (prospect) => {
        let response = await apiService.post(prospectsService.endpoint, prospect);
        if (!response) {
            response = {
                ok: false,
                message: "Could not update the prospect",
            }
        } else {
            response = {
                ok: true,
            }
        }
        return response;
    },
    deleteItem: async (prospect) => {
        let endpoint = `${prospectsService.endpoint}/${prospect._id}?rev=${prospect._rev}`,
            response = await apiService.delete(endpoint);
        if (!response) {
            response = {
                ok: false,
                message: "Could not delete the prospect",
            }
        }
        return response;
    },
};

export default prospectsService;
