import React from 'react';
import { Box, CircularProgress, Fade, Typography } from '@mui/material';
import { BehaviorSubject } from 'rxjs';
import useBehaviorSubject from '../../_core/hooks/useBehaviorSubject';
import progress, { ProgressBackground } from './progressController';

const styles = {
    overlay: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 65535,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        transition: 'opacity .2s ease-in',
    },
    solidOverlayBackground: {
        backgroundColor: 'background.default',
    },
    transparentOverlayBackground: {
        backgroundColor: 'transparent',
    },
};

const ModalProgress = ({ controller, ...stateOverrides }) => {
    const controllerState = useBehaviorSubject(controller && controller.subject$ || new BehaviorSubject(null));

    const state = { // yes, no useMemo here
        ...(controllerState || {}),
        ...stateOverrides,
    };

    return <Box sx={{
        ...styles.overlay,
        ...(state.background == ProgressBackground.Solid ? styles.solidOverlayBackground : {}),
        ...(state.background == ProgressBackground.Transparent ? styles.transparentOverlayBackground : {}),
    }}
        style={{
            opacity: state.loading ? 1 : 0,
            pointerEvents: state.loading ? 'all' : 'none',
        }}
    >
        <Fade
            in={state.loading}
            style={{
                transitionDelay: state.loading ? '200ms' : '0ms',
            }}
            unmountOnExit
        >
            <CircularProgress color="primary" />
        </Fade>
        {state.message ? <Typography variant="subtitle1" sx={{
            maxWidth: '90%',
            marginTop: 2,
            paddingY: 1,
            paddingX: 2,
            textAlign: 'center',
            borderRadius: 2,
            boxShadow: 4,
            background: state.background == ProgressBackground.Solid ? 'transparent' : 'background.dark',
            color: state.background == ProgressBackground.Solid ? 'text.primary' : 'text.light',
        }}>{state.message}</Typography> : null}
    </Box>;
};

export const ModalProgressWrapper = () => <ModalProgress controller={progress} />;

export default ModalProgress;
