// Shortcuts to be used with import { useMediaQuery } from '@mui/material'; (to keep our code DRY :) )
// e.g. const isSmallScreen = useMediaQuery(mqSmall);

export const mqSmall = theme => theme.breakpoints.down('sm');

export const mqSmallUp = theme => theme.breakpoints.up('sm');

export const mqMedium = theme => theme.breakpoints.up('sm') && theme.breakpoints.down('md');

export const mqMediumDown = theme => theme.breakpoints.down('md');

export const mqLarge = theme => theme.breakpoints.up('md');

export const mqLargeDown = theme => theme.breakpoints.down('lg');
