export const FilterType = {
    Boolean: 'Boolean',
    Text: 'Text',
    Number: 'Number',
    Select: 'Select',
    Date: 'Date',
    DateTime: 'DateTime',
};

export const FilterTextMatch = {
    StartsWith: 'Starts With',
    EndsWith: 'Ends With',
    Contains: 'Contains',
    Exact: 'Exact',
}
