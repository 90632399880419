import { BehaviorSubject } from 'rxjs';
import logger from '../../utils/logger';

export const ProgressBackground = {
    Transparent: 'Transparent',
    Dimmed: 'Dimmed',
    Solid: 'Solid',
};

export const ProgressOverride = {
    Always: 'Always',
    NotIfMessagesPresent: 'NotIfMessagesPresent',
    Never: 'Never',
}

const defaultShowOptions = {
    autoHideMs: 30 * 1000, //ms until auto-hide if hide() hasn't been called in the meantime
    background: ProgressBackground.Dimmed, // style of background
    messages: [], //array of objects with { message: <any>, timeout: <number> }, where `message` will be rendered in React, and `timeout` is the time it will stay displayed until next message. On the last message, set timeout to 0 so the message stays indefinetly until hide().
    messagesDelayMs: 0, //ms until the first message is triggered
    override: ProgressOverride.Always, //choose if and when a new call to progress.show may override an existing progress
}

export class ProgressController {
    subject$ = new BehaviorSubject(null);

    show = (options = defaultShowOptions) => {
        if (options.override == ProgressOverride.Never && this.hideTimeout) {
            return;
        }

        if (options.override == ProgressOverride.NotIfMessagesPresent && this.hideTimeout && this.subject$.getValue() && this.subject$.getValue().message) {
            return;
        }

        clearTimeout(this.hideTimeout);

        options = {
            ...defaultShowOptions,
            ...options,
            autoHideMs: !options.autoHideMs || options.autoHideMs < 0 ? defaultShowOptions.autoHideMs : options.autoHideMs, // don't accept negative values
        }

        this.subject$.next({
            loading: true,
            background: options.background,
            options,
        });

        if (options.messages && options.messages.length && options.messages[0]) {
            this.messageTimeout = setTimeout(this.setMessage(options.messages, 0), options.messagesDelayMs);
        }

        this.hideTimeout = setTimeout(this.hide, options.autoHideMs);
    }

    isShown = () => this.subject$.getValue();

    hide = () => {
        clearTimeout(this.hideTimeout);
        clearTimeout(this.messageTimeout);

        this.hideTimeout = null;
        this.messageTimeout = null;

        this.subject$.next(null);
    }

    setMessage = (messages, index) => () => {
        if (!messages || index >= messages.length) {
            return; // finished
        }

        const message = messages[index] && messages[index].message;

        if (!message) {
            return this.setMessage(messages, index + 1); // skip empty messages
        }

        this.subject$.next({
            ...this.subject$.getValue(),
            message,
        });

        if (window.initialLoaderMessage) {
            window.initialLoaderMessage(message);
        }

        if (index < messages.length - 1 && messages[index].timeout) {
            this.messageTimeout = setTimeout(this.setMessage(messages, index + 1), messages[index].timeout);
        }
    }

    /**
     * Wraps a call to a function with a progress indicator shown while it's executing
     * @param {Function} fn [required] A sync/async function that does something
     * @param {Object} options [optional] options for the `show()` call (to set up messages & whatnot)
     * @param {Array} fnArgs [optional] args for invoking `fn` with. Not much point using it directly, but useful to propagate args from `getWrapped()` below.
     */
    wrap = async (fn = () => { }, options = defaultShowOptions, ...fnArgs) => {
        let response = null;

        this.show(options);
        try {
            response = await fn(...fnArgs);
        } catch (e) {
            logger.error(e);
        }
        this.hide();

        return response;
    }

    /**
     * Gets a function that will wrap a call to your function in a show/hide
     * @param {Function} fn [required] A sync/async function that does something
     * @param {Object} options [optional] options for the show() call (to set up messages & whatnot)
     * @returns {Function} a function that will call wrap(fn)
     */
    getWrapped = (fn = () => { }, options = defaultShowOptions) => async (...fnArgs) => this.wrap(fn, options, ...fnArgs);
};

const progress = new ProgressController();

window.progress = progress;

export default progress;
