import CouponLinkCell from '../../../components/lcTable/cells/couponLinkCell';
import DateTimeCell from '../../../components/lcTable/cells/dateTimeCell';

export const columns = [{
    Header: 'Type',
    accessor: 'userType',
}, {
    Header: 'Programme',
    accessor: 'programme',
}, {
    Header: 'Email',
    accessor: 'email',
}, {
    Header: 'First Name',
    accessor: 'firstName',
}, {
    Header: 'Last Name',
    accessor: 'lastName',
},
{
    Header: 'Coupon',
    accessor: 'coupon',
    Cell: CouponLinkCell,
}, {
    Header: 'User',
    accessor: 'userId',
}, {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateTimeCell,
}];
