import { loggedIn$ } from '../subjects';
import { publicRoutes } from '../routes/routes';
import dataService from './dataService';
import logger from '../../utils/logger';
import storage from '../../utils/storage';
import apiService from './apiService';
import history from '../history';
import progress from '../../components/modalProgress/progressController';

class AuthService {
    verifyExistingLogin = async () => {
        let { user } = await storage.get('currentSession') || {};

        // Logged in already?
        if (user && user.username) {
            logger.log('[AuthService::verifyExistingLogin] Already logged in')
            return await this.onLoginSuccess(user);
        }

        logger.log('[AuthService::verifyExistingLogin] Not logged in');
        return null;
    };

    loginWithCredentials = async (username, password) => {
        logger.log('[AuthService::loginWithCredentials] login with', username);

        const response = await apiService.post('_session', { name: username, password });

        logger.log('[AuthService::loginWithCredentials] response', response);

        if (!response || !response.ok) {
            console.error('[AuthService::loginWithCredentials]', response);
            return response;
        }

        // const user = await apiService.get('/user');
        const user = {
            username
        };

        if (!user) {
            return { success: false, status: 404 };
        }

        await this.onLoginSuccess(user);

        return { success: true, user };
    };

    onLoginSuccess = async (user) => {
        if (!user) {
            await storage.unset('currentSession');
        }

        if (user) {
            await storage.set('currentSession', { user });
            await dataService.onLogin({ user });
        }

        logger.setUsername(user.username);

        loggedIn$.next({ user });
        logger.log('[AuthService::onLoginSuccess]', user);

        return user;
    };

    updateSession = async session => {
        const newSession = { ...loggedIn$.value, ...session };
        await storage.set('currentSession', newSession);
        loggedIn$.next(newSession);
    };

    logout = async () => {
        await dataService.onLogout();

        await storage.set('currentSession', null);

        logger.clearUsername();
        loggedIn$.next(false);

        // prevent hanging loaders
        progress.hide();
        window.hideInitialLoader && window.hideInitialLoader();

        history.push(publicRoutes.landing);
    };
}

export default new AuthService();
