import React, { useCallback, useRef, useState } from 'react';
import { Badge, Box, Button, Popover } from '@mui/material';
import { FilterOutline } from 'mdi-material-ui';
import FilterForm from './filterForm';

export default function FilterPopoverTrigger({ icon, text = 'Filter', initialValues, onSubmit, Component }) {
    const [showFilters, setShowFilters] = useState(false);
    const canCloseRef = useRef(null);
    const setCanClose = useCallback(fn => canCloseRef.current = fn, []);

    const filtersButtonRef = useRef();

    return <>
        <Badge badgeContent="!" color="warning" sx={{ '.MuiBadge-badge.MuiBadge-anchorOriginTopRight': { top: 4 } }} invisible={!Object.keys(initialValues || {}).length}>
            <Button onClick={() => setShowFilters(true)} ref={filtersButtonRef} color="inherit">{icon || <FilterOutline />}{text}</Button>
        </Badge>
        <Popover
            open={showFilters}
            onClose={async () => {
                if (!canCloseRef.current) {
                    setShowFilters(false);
                } else {
                    const response = await canCloseRef.current();
                    response && setShowFilters(false);
                }
            }}
            anchorEl={filtersButtonRef.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            // BackdropProps={{ sx: { bgcolor: theme.palette.action.disabledBackground } }}
            PaperProps={{ sx: { overflow: 'hidden', p: 1, width: { lg: '50%', md: '62.5%', sm: '75%', xs: '90%' } } }}
        >
            <Box sx={{ p: 1, overflow: 'auto', maxHeight: '50vh', width: '100%' }}>
                <FilterForm
                    Component={Component}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        onSubmit(values);
                        setShowFilters(false);
                    }}
                    setCanClose={setCanClose}
                />
            </Box>
        </Popover>
    </>;
}