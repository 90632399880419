import React, { useCallback, useEffect, useState } from 'react';
import { columns } from './subscriptions.config';
import LCTable from '../../../components/lcTable/lcTable';
import { openEditPopupAsync } from '../../../components/popup/editPopup';
import { dialogConfirmAsync, dialogOpenAsync } from '../../../components/dialog';
import progress from '../../../components/modalProgress/progressController';
import SubscriptionsFilterForm from './SubscriptionsFilterForm';
import subscriptionsService, { UserType } from '../../../_core/services/subscriptionsService';
import SubscriptionForm from './subscriptionForm';
import { Download, Fence, FenceElectric } from 'mdi-material-ui';
import { Button, IconButton } from '@mui/material';
import csvUtils from '../../../utils/csvUtils';
import RowDetailsJson from '../../../components/lcTable/rowDetailsJson';

// This is used in Edit popup header AND the Delete confirmation dialog
const RowDisplayName = ({ row }) => {
    const { firstName, lastName, email } = row?.original || {};
    return firstName || lastName || email ? <>{firstName} {lastName} <strong>({email})</strong></> : <strong>Unnamed</strong>;
}

export default function SubscriptionsPage() {
    const getData = subscriptionsService.getList;

    // onAddRow should just return the added row so the table can render it
    const onAddRow = useCallback(async () => {
        const item = await openEditPopupAsync({
            Content: UserForm,
            title: 'Add New',
        });
        console.log('response', item);
        if (!item) { return; }

        //const result = await insertItem(item);
        const result = await progress.wrap(async () => await sleep(1000));

        return result;
    }, []);

    // onEditRow should just return the added row so the table can re-render it
    const onEditRow = useCallback(async row => {
        console.log('edit', row);
        const initialValues = row?.original || {},
            item = await openEditPopupAsync({
                title: <RowDisplayName row={row} />,
                Content: SubscriptionForm,
                initialValues,
            });
        console.log('response', item);
        if (!item) { return; }

        if (initialValues.userType != item.userType) {
            let currentCampaign = await subscriptionsService.getCurrentCampaign();
            if (!currentCampaign) {
                console.log('there is an error in getting the current campaign');
            }
            else {
                if (item.userType != UserType.standard) {
                    item.subscriptionLimitId = currentCampaign.premiumSubscriptionLimitId;
                }
                else{
                    item.subscriptionLimitId = currentCampaign.standardSubscriptionLimitId;
                }
            }
        }

        const hasUserTypeChanged = true, //item.userType != initialValues.userType,
            result = await progress.wrap(() => subscriptionsService.updateItem(item, hasUserTypeChanged));

        return result;
    }, []);

    // onDeleteRow should just return true/false if deletion worked or not, so the table can delete the row
    const onDeleteRow = useCallback(async row => {
        console.log('delete', row);
        const confirmed = await dialogConfirmAsync({
            title: 'Are you sure?',
            content: <>DELETING <RowDisplayName key={2} row={row} /></>,
        });
        if (!confirmed) { return; }

        //const result = await deleteItem(item);
        const result = await progress.wrap(async () => await sleep(1000));

        return result;
    }, []);

    const onDownload = useCallback(async () => {
        if (getCurrentListOptions) {
            let currentOptions = getCurrentListOptions();
            let allDocs = await subscriptionsService.getList({ pageIndex: 0, pageSize: 1000, filters: currentOptions.filters });
            if (allDocs && allDocs.data) {
                let dataForCSV = allDocs.data.map(r => [r.name, r.email]);
                let csv = dataForCSV.map(e => e.join(",")).join("\n");
                csvUtils.getCSV(csv);
            }
        }
    }, []);

    const onViewLimits = useCallback(row => async () => {
        const item = row?.original || {};

        if (!item.subscriptionLimitId) {
            await dialogOpenAsync('This subscription is not correctly configured');
            return;
        }
        progress.show();
        let userDbEndpoint = subscriptionsService.getUserDbName(subscriptionsService.getUserNameFromUserId(item._id)),
            userDBDocEndpoint = `${userDbEndpoint}/${subscriptionsService.subscriptionDocId}`,
            userSubscription = await apiService.get(userDBDocEndpoint);
        progress.hide();
        let subscription = userSubscription;
        if (userSubscription && userSubscription.ok) {
            subscription = userSubscription.data;
        }
        item = await openEditPopupAsync({
            title: <div>{subscription.userId}</div>,
            Content: () => <RowDetailsJson row={{ original: subscription }} />,
        });
    }, []);

    let getCurrentListOptions = null;
    const setCurrentOptionsCallback = (fn) => getCurrentListOptions = fn;

    return <LCTable columns={columns}
        FiltersForm={SubscriptionsFilterForm}
        getData={getData}
        onEditRow={onEditRow}
        getRowActions={row => <IconButton onClick={onViewLimits(row)}><FenceElectric /></IconButton>}
        headerActions={<Button onClick={onDownload} color="inherit" sx={{ ml: 2 }}><Download />&nbsp;Download CSV</Button>}
        setCurrentOptionsCallback={setCurrentOptionsCallback}

    // onAddRow={onAddRow}
    // onDeleteRow={onDeleteRow}
    />;
};
