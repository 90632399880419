import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { merge } from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import useBehaviorSubject from '../_core/hooks/useBehaviorSubject';
import progress from './modalProgress/progressController';

const dialog$ = new BehaviorSubject({ open: false });

export const dialogClose = (...args) => {
    const { onClose, wasInProgress } = dialog$.getValue();

    onClose?.(...args);

    wasInProgress && progress.show(wasInProgress);

    dialog$.next({ open: false });
};

const defaultActions = (okText = 'Ok') => <Button key="ok" onClick={dialogClose} variant="contained">{okText}</Button>;

const confirmActions = (okText = 'Ok', cancelText = 'Cancel') => <>
    <Button key="cancel" onClick={() => dialogClose(false)} variant="text.primary">{cancelText}</Button>
    <Button key="ok" onClick={() => dialogClose(true)} variant="contained">{okText}</Button>
</>;

const defaultOptions = {
    title: null,
    content: null,
    actions: defaultActions('Ok'),
    dialogProps: {},
    onClose: null,
    okText: 'Ok',
    cancelText: 'Cancel',
};

export const dialogOpen = (options = defaultOptions) => {
    const wasInProgress = progress.isShown();
    wasInProgress && progress.hide();

    dialog$.next(merge({},
        defaultOptions,
        typeof (options) == 'string' ? { content: options } : options,
        {
            open: true,
            wasInProgress,
        },
    ));
}

export const dialogOpenAsync = (options = defaultOptions) => new Promise(resolve => dialogOpen(merge({},
    typeof (options) == 'string' ? { content: options } : options,
    {
        actions: defaultActions(options.okText),
        onClose: () => (options.onClose?.(), resolve()),
    },
)));

export const dialogConfirmAsync = (options = defaultOptions) => new Promise(resolve => dialogOpen(merge({},
    typeof (options) == 'string' ? { content: options } : options,
    {
        actions: confirmActions(options.okText, options.cancelText),
        onClose: response => (options.onClose?.(response), resolve(response)),
    },
)));

const DialogWrapper = () => {
    const { open, title, content, actions = null, dialogProps = {}, okText } = useBehaviorSubject(dialog$);

    return <Dialog
        open={open}
        onClose={dialogClose}
        aria-labelledby="alert-dialog-title"
        fullWidth
        {...dialogProps}
        sx={{
            zIndex: 6000,
        }}
    >
        {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
        {content ? <DialogContent>
            {typeof (content) == 'string'
                ? <DialogContentText>
                    {content}
                </DialogContentText>
                : content}
        </DialogContent> : null}
        <DialogActions>
            {actions || defaultActions(okText)}
        </DialogActions>
    </Dialog>;
};

export default DialogWrapper;
