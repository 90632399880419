import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { merge } from 'lodash';
import progress from '../modalProgress/progressController';
import useBehaviorSubject from '../../_core/hooks/useBehaviorSubject';
import popupStyle from './popup.style';
import { AppBar, Box, Button, Modal, Paper, Toolbar, Typography } from '@mui/material';
import { Close } from 'mdi-material-ui';
import { dialogOpenAsync } from '../dialog';

const defaultPopupInfo = {
    noHeader: false,
    icon: null,
    title: null,
    actions: [], // array of objects like { icon, text, callback }
    defaultCloseAction: true, // only if actions is empty
    componentFactory: null, // factory function called with popup level & props and returns a (possibly connected) Component to render
    Component: null, // if factory not needed, straight up Component to render
    props: {}, // props passed to Component,
    onClosed: () => { },
    canClose: () => true,
    style: {
        overlay: popupStyle.overlay,
        popup: popupStyle.popup,
        content: popupStyle.content,
        button: popupStyle.button,
    },
};

let openPopups$ = new BehaviorSubject([]),
    closeWrappers = {};

// export const popupOpenAsync = (popupInfo = defaultPopupInfo) => {
//     return new Promise((resolve, reject) => setTimeout(() => {
//         popupOpen(merge(popupInfo, {
//             props:
//             {
//                 onSubmitSuccessCallback: (values) => {
//                     resolve(values);
//                 },
//                 onSubmitFail: (err) => {
//                     if (!popupInfo.useSubmitFailOnValidationError) {
//                         reject(err);
//                     }
//                 },
//                 callback: (values) => {
//                 },
//             },
//         }));
//     }, 1));
// }

export const popupOpen = (popupInfo = defaultPopupInfo) => {
    progress.hide();

    popupInfo = merge({}, defaultPopupInfo, popupInfo || {});

    let popupLevel = openPopups$.getValue().length;

    if (!popupInfo.Component && popupInfo.componentFactory) {
        let factoryProduct = popupInfo.componentFactory(popupLevel, popupInfo.props);
        // componentFactory may return a React component directly or an Object with default initial props
        // which must include a Component field (the React component to render)
        if (factoryProduct.Component) {
            popupInfo = {
                ...defaultPopupInfo,
                ...popupInfo,
                ...factoryProduct,
            };
        } else {
            popupInfo = {
                ...defaultPopupInfo,
                ...popupInfo,
                Component: factoryProduct,
            };
        }
    }

    if (!popupInfo.Component) {
        console.error('Opening popup without Component or componentFactory', popupInfo);
        return null;
    }

    !closeWrappers[popupLevel] && (closeWrappers[popupLevel] = () => popupClose(popupLevel));

    document.addEventListener('backbutton', closeWrappers[popupLevel]);

    if (!window.device && popupLevel == 0) {
        document.addEventListener('keyup', _closeOnEsc);
    }

    openPopups$.next([...openPopups$.getValue(), popupInfo]);
    return popupLevel;
}

export const popupGetInfo = (index) => [...openPopups$.getValue()][index];

export const popupUpdate = (index, popupInfo = defaultPopupInfo) => {
    const v = [...openPopups$.getValue()];
    v[index] = { ...v[index], ...popupInfo };
    openPopups$.next(v);
}

const _closeOnEsc = e => {
    if ((e.which || e.keyCode) == 27 || e.key == 'Escape') {
        popupClose(openPopups$.getValue().length - 1);
    }
};

const _close = (popupLevel, afterClose) => {
    let v = [...openPopups$.getValue()];

    if (v[popupLevel] && v[popupLevel].onClosed) {
        setTimeout(v[popupLevel].onClosed, 0);
    }
    v.splice(popupLevel, 1);

    document.removeEventListener('backbutton', closeWrappers[popupLevel]);

    if (!window.device && !popupLevel) {
        document.removeEventListener('keyup', _closeOnEsc);
    }

    openPopups$.next(v);
    afterClose && setTimeout(afterClose, 0);
};

export const popupClose = (index = 0, onClose = () => { }, onCancelledClose = () => { }) => {
    index = index >= 0 ? index : (openPopups$.getValue().length - 1);
    onClose = onClose || (() => { });
    onCancelledClose = onCancelledClose || (() => { });

    if (index < 0) {
        onClose && onClose();
        return true;
    }

    let v = [...openPopups$.getValue()],
        popupInfo = v[index],
        performClose = shouldClose => shouldClose ? _close(index, onClose) : onCancelledClose();

    if (popupInfo && popupInfo.canClose) {
        let result = popupInfo.canClose();
        if (result && result.then) {
            result.then(performClose);
        } else {
            performClose(result);
        }
    } else {
        performClose(true);
    }
}

// returns true if there was a popup to try and close, false if nothing to do
export const popupOnNavigationAttempt = () => {
    if (openPopups$.getValue().length <= 0) {
        return false;
    } else {
        popupClose();
        return true;
    }
}

export default function PopupWrapper() {
    const openPopups = useBehaviorSubject(openPopups$);

    return openPopups.map((popup, index) => {
        return <Modal key={index} open
            className={'popup level-' + index}
            onClose={() => popupClose(index)}
            sx={{ ...popup.style.overlay }}
        >
            <Paper elevation={8} sx={{ ...popup.style.popup }}>
                {popup.noHeader ? null
                    : <AppBar position="static">
                        <Toolbar sx={{ pr: { sm: 1, xs: 0 } }}>
                            {popup.icon ?? null}
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {popup.title}
                            </Typography>
                            {popup.actions && popup.actions.length ? <>
                                {popup.actions.map((action, actionKey) => {
                                    return <Button key={actionKey} color="inherit" sx={{ ...popup.style.button, ...(action.style ?? {}) }} onClick={action.callback}>
                                        {action.icon ?? null}
                                        {action.text}
                                    </Button>
                                })}
                            </> : (popup.defaultCloseAction
                                ? <Button color="inherit" sx={{ ...popup.style.button }} onClick={() => popupClose(index)}><Close /></Button>
                                : null)}
                        </Toolbar>
                    </AppBar>}
                <Box sx={{ ...popup.style.content }}>
                    <popup.Component {...popup.props} inPopup popupLevel={index} popupInfo={popup} />
                </Box>
            </Paper>
        </Modal>;
    });
};

// export default function PopupWrapper() {
//     const openPopups = useBehaviorSubject(openPopups$);

//     const onOverlayClick = level => e => {
//         if (e && e.target === e.currentTarget) {
//             popupClose(level);
//         }
//         return true;
//     }

//     const onClick = fn => e => {
//         fn();
//         return cancelEvent(e);
//     }

//     return <Box sx={{ ...popupStyle.container, ...(openPopups.length ? popupStyle.containerActive : {}) }}>
//         {openPopups.map((popup, index) => {
//             return <Box key={index} sx={{ ...popup.style.overlay, zIndex: index + 1 }} className={'popup level-' + index}
//             //onClick={onOverlayClick(index)}
//             >
//                 <Paper elevation={8} sx={{ ...popup.style.popup }}>
//                     {popup.noHeader ? null
//                         : <header className="mobile">
//                             <h1>
//                                 {popup.icon ? <i className={classNames('ei', popup.icon)} /> : null}
//                                 {popup.title}
//                             </h1>
//                             {popup.actions && popup.actions.length ? <ul>
//                                 {popup.actions.map((action, actionKey) => {
//                                     return <li key={actionKey} className={classNames('action', action.className)} onClick={onClick(action.callback)}>
//                                         {action.icon ? <i className={classNames('ei', action.icon)} /> : null}
//                                         {action.text}
//                                     </li>
//                                 })}
//                             </ul> : (popup.defaultCloseAction ? <ul>
//                                 <li className="action" onClick={onClick(() => popupClose(index))}><i className="ei ei-close2" />Close</li>
//                             </ul> : null)}
//                         </header>}
//                     <Box sx={{ ...popup.style.content }}>
//                         <popup.Component {...popup.props} inPopup popupLevel={index} popupInfo={popup} />
//                     </Box>
//                 </Paper>
//             </Box>;
//         })}
//     </Box>;
// };
