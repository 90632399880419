import AvatarCell from '../../../components/lcTable/cells/avatarCell';
import BooleanCell from '../../../components/lcTable/cells/booleanCell';
import DateTimeCell from '../../../components/lcTable/cells/dateTimeCell';
import FeedbackTypeCell from '../../../components/lcTable/cells/feedbackTypeCell';

export const columns = [{
    Header: 'Type',
    accessor: 'feedbackType',
    Cell: FeedbackTypeCell,
}, {
    Header: 'Status',
    accessor: 'status',
}, {
    Header: 'Rating',
    accessor: 'rating',
}, {
    Header: 'Message',
    accessor: 'message',
}, {
    Header: 'User',
    accessor: 'createdBy',
}, {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateTimeCell,
}];
