import React from 'react';
import ReactDOM from 'react-dom';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
// import DateAdapter from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import history from './history';
import { UserProfileProvider } from './contexts/userProfileContext';
import ApplicationLayout from './layout/applicationLayout';
import theme from './theme';
import { RecoilRoot } from 'recoil';

const getUserConfirmation = (message, callback) => {
    // this can be made async and use e.g. an in-app dialog
    let response = window.confirm(message);
    callback(response);
};

// TODO: UserProfileProvider is essentially a subset of Recoil with just 1 hard-coded atom in it: the currently logged in user
// We should create an atom and replace UserProfileProvider with it across the entire app (then delete the UserProfile context)
const App = () => {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RecoilRoot>
                <UserProfileProvider>
                    <Router history={history} getUserConfirmation={getUserConfirmation}>
                        <ApplicationLayout />
                    </Router>
                </UserProfileProvider>
            </RecoilRoot>
        </ThemeProvider>
    </LocalizationProvider>;
};

export default () => {
    ReactDOM.render(<App />, document.getElementById('root'));
};
