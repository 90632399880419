const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

const menuTitle = {
    ...item,
    ...itemCategory,
    fontSize: 22,
    color: 'common.white',
    '&:hover, &:focus': {
        bgcolor: 'transparent',
    },
};

export default {
    item,
    itemCategory,
    menuTitle,
};
