import React, { Fragment } from 'react';
import { TransitionGroup, Transition } from 'react-transition-group';

const settings = {
    duration: 350,
};

// TODO: create generic animation utility (move this someplace else) and use it from there
const animate = (element, target) => {
    // TODO: add actual animation between screens
    element.style.opacity = target;
    if (target) {
        element.style.display = '';
    } else {
        element.style.display = 'none';
    }
};

export default ({ location, children }) => <TransitionGroup component={Fragment}>
    <Transition
        key={location.key}
        timeout={settings.duration}
        unmountOnExit={true}
        onEnter={node => animate(node, 1)}
        onExit={node => animate(node, 0)}
        appear={true}>
        <div className="transition-container">{children}</div>
    </Transition>
</TransitionGroup>;
