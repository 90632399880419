import { useState, useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';

const useBehaviorSubject = (observable$ = new BehaviorSubject()) => {
    const [value, setValue] = useState(observable$.getValue());

    useEffect(() => {
        const sub = observable$.subscribe(setValue);
        return () => sub.unsubscribe();
    }, [observable$]);

    return value;
};

export default useBehaviorSubject;
