// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */ }

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative; }

body {
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  body.iosXR {
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    bottom: calc(constant(safe-area-inset-bottom) / 2);
    bottom: calc(env(safe-area-inset-bottom) / 2); }
    body.iosXR.full-screen {
      top: 0;
      bottom: 0; }
    @media (orientation: landscape) {
      body.iosXR {
        top: 0;
        bottom: 0;
        left: constant(safe-area-inset-left);
        left: env(safe-area-inset-left); }
        body.iosXR.full-screen {
          left: 0; } }

#root,
.transition-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/scss/index.scss"],"names":[],"mappings":"AAAA;EACI,6CAA6C;EAC7C,sBAAsB;EACtB,wEAAA,EAAyE;;AAG7E;;EAEI,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,kBAAkB,EAAA;;AAGtB;EACI,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS,EAAA;EANb;IASQ,kCAAkC;IAClC,6BAA6B;IAC7B,kDAAkD;IAClD,6CAA6C,EAAA;IAZrD;MAeY,MAAM;MACN,SAAS,EAAA;IAGb;MAnBR;QAqBgB,MAAM;QACN,SAAS;QACT,oCAAoC;QACpC,+BAA+B,EAAA;QAxB/C;UA2BoB,OAAO,EAAA,EACV;;AAMjB;;EAEI,WAAW;EACX,YAAY;EACZ,SAAS;EACT,UAAU,EAAA","sourcesContent":["* {\n    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n    box-sizing: border-box;\n    /* make transparent link selection, adjust last value opacity 0 to 1.0 */\n}\n\nhtml,\nbody {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n    position: relative; // fixes viewport scroll of the entire page on iOS (overflow works by itself on other browsers)\n}\n\nbody {\n    height: auto;\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n\n    &.iosXR {\n        top: constant(safe-area-inset-top);\n        top: env(safe-area-inset-top);\n        bottom: calc(constant(safe-area-inset-bottom) / 2);\n        bottom: calc(env(safe-area-inset-bottom) / 2);\n\n        &.full-screen {\n            top: 0;\n            bottom: 0;\n        }\n\n        @media (orientation: landscape) {\n            & {\n                top: 0;\n                bottom: 0;\n                left: constant(safe-area-inset-left);\n                left: env(safe-area-inset-left);\n\n                &.full-screen {\n                    left: 0;\n                }\n            }\n        }\n    }\n}\n\n#root,\n.transition-container {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
