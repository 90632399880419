import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { Field } from 'react-final-form';

export default function UserForm({ ...props }) {
    // console.log('Content props', props);
    return <Grid container spacing={3} sx={{ px: 2, pt: 1, pb: 3 }}>
        <Grid item container xs={12}>
            <Field name="_id" component={({ input: { value } }) => value ? <Typography variant="caption" color="text.disabled">{`_id: ${value}`}</Typography> : null} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="firstName" label="First Name" required autoFocus />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="lastName" label="Last Name" />
        </Grid>
    </Grid>;
};
