import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, Collapse, Divider, IconButton, Input, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography, useTheme } from '@mui/material';
import { ArrowDown, ArrowUp, ChevronDown, ChevronRight, ChevronDoubleLeft, ChevronLeft, ChevronDoubleRight, DeleteOutline, Plus, Reload, SquareEditOutline } from 'mdi-material-ui';
import { useExpanded, usePagination, useTable } from 'react-table';
import RowDetailsJson from './rowDetailsJson';
import progress from '../modalProgress/progressController';
import logger from '../../utils/logger';
import getDeepClone from '../../utils/getDeepClone';
import FilterPopoverTrigger from './filters/filterPopoverTrigger';
import { dialogOpenAsync } from '../dialog';

export default function LCTable({
    columns: initialColumns,
    getData = () => { },
    headerActions = null,
    onAddRow = null,
    onEditRow = null,
    onDeleteRow = null,
    expandRows = true,
    RowDetails = RowDetailsJson,
    FiltersForm = null,
    getRowActions = (row) => null,
    setReloadCallback = null,
    setCurrentOptionsCallback = null,
}) {
    const [data, setData] = useState([]); // current page rows
    const [pageCount, setPageCount] = useState(-1); // controlled pagination
    const [filters, setFilters] = useState({}); // currently applied filters
    const refreshOptionsRef = useRef({}); // last request options, used by the Refresh button to re-issue the latest request

    // Important to useMemo!
    const columns = useMemo(() => {
        let columns = [...initialColumns];

        // Add a cell to expand/collapse rows
        expandRows && columns.unshift({
            Header: '',
            id: 'expander', // It needs an ID
            Cell: ({ row }) => <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ChevronDown /> : <ChevronRight />}
            </IconButton>,
        });

        (onEditRow || onDeleteRow) && columns.push({
            Header: '',
            id: 'actions', // It needs an ID
            Cell: ({ row }) => {
                // console.warn('row', row, row.getToggleRowExpandedProps(), row.getRowProps());
                return <Fragment key={row.getRowProps()?.key ?? row.index}>
                    {onEditRow && <IconButton onClick={() => editRow(row)}><SquareEditOutline /></IconButton>}
                    {onDeleteRow && <IconButton onClick={() => deleteRow(row)}><DeleteOutline /></IconButton>}
                    {getRowActions?.(row)}
                </Fragment>;
            },
        });

        return columns;
    }, [initialColumns, data, setData, expandRows, editRow, onEditRow, deleteRow, onDeleteRow]);

    const {
        getTableBodyProps,
        getTableProps,
        headerGroups,
        prepareRow,
        rows,
        visibleColumns,
        setPageSize,
        pageOptions,
        canPreviousPage,
        canNextPage,
        gotoPage,
        previousPage,
        nextPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
            },
            manualPagination: true,
            pageCount,
            paginateExpandedRows: false,
        },
        useExpanded,
        usePagination,
    );

    const getDataAndRefreshTable = useCallback(progress.getWrapped(async (options = {}) => {
        refreshOptionsRef.current = getDeepClone(options);

        const response = await getData(options);
        logger.log('[LCTable::getDataAndRefreshTable]', options, response);

        if (response && response.data) {
            setData(response.data);
            setPageCount(Math.ceil((response.pagination?.filtered || 0) / options.pageSize));
        } else {
            logger.log('[LCTable::getDataAndRefreshTable] Failed:', response);
            await dialogOpenAsync(response && response.message || 'There was an error loading your data!');
        }
    }), [getData]);

    useEffect(() => {
        // no need to await this, even though it's async. This is just an effect that we fire & forget here.
        getDataAndRefreshTable({ pageIndex, pageSize, filters });
        if (setReloadCallback) {
            setReloadCallback(() => { getDataAndRefreshTable(refreshOptionsRef.current) });
        }
        if (setCurrentOptionsCallback) {
            setCurrentOptionsCallback(() => refreshOptionsRef.current);
        }
    }, [getData, pageIndex, pageSize, filters]);

    const addRow = useCallback(progress.getWrapped(async () => {
        const response = await onAddRow();

        if (response && response.ok) {
            getDataAndRefreshTable(refreshOptionsRef.current);
        } else if (response) {
            logger.log('[LCTable::addRow] Failed:', response);
            await dialogOpenAsync(response.message || 'Add failed!');
        } else {
            logger.log('[LCTable::addRow] Cancelled?', response);
        }
    }), [data, onAddRow]);

    const editRow = useCallback(progress.getWrapped(async row => {
        const response = await onEditRow(row);

        if (response && response.ok) {
            getDataAndRefreshTable(refreshOptionsRef.current);
        } else if (response) {
            logger.log('[LCTable::editRow] Failed:', response);
            await dialogOpenAsync(response && response.message || 'Edit failed!');
        } else {
            logger.log('[LCTable::editRow] Cancelled?', response);
        }
    }), [data, onEditRow]);

    const deleteRow = useCallback(progress.getWrapped(async row => {
        const response = await onDeleteRow(row);

        if (response && response.ok) {
            getDataAndRefreshTable(refreshOptionsRef.current);
        } else if (response) {
            logger.log('[LCTable::deleteRow] Failed:', response);
            await dialogOpenAsync(response.message || 'Delete failed!');
        } // else cancelled
    }), [data, onAddRow]);

    const theme = useTheme();

    return <Paper sx={{ overflow: 'hidden', flexGrow: 1, maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
        <Toolbar sx={{ py: 1, bgcolor: theme.palette.primary.dark, color: theme.palette.primary.contrastText, flexShrink: 0 }}>
            {FiltersForm ? <FilterPopoverTrigger
                initialValues={{ ...filters }}
                onSubmit={(values) => {
                    setFilters(values);
                    gotoPage(0);
                }}
                Component={FiltersForm}
            /> : null}
            {onAddRow ? <Button onClick={addRow} color="inherit" sx={{ ml: 2 }}><Plus />&nbsp;Add New</Button> : null}
            {headerActions}
            <Box sx={{ flex: 1 }} />
            <IconButton color="inherit" onClick={() => getDataAndRefreshTable(refreshOptionsRef.current)}><Reload /></IconButton>
        </Toolbar>
        <Box sx={{ overflowY: 'auto', flexGrow: 1, minHeight: 0 }}>
            <Table {...getTableProps()} sx={{ borderCollapse: 'separate' }}>
                <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
                    {headerGroups.map(headerGroup => {
                        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps() || {},
                            canFilter = headerGroup.headers.some(col => col.canFilter);

                        return <Fragment key={key}>
                            <TableRow {...headerGroupProps} sx={{ bgcolor: theme.palette.background.paper, boxShadow: canFilter ? '' : theme.shadows[1] }}>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column => {
                                        // Apply the header cell props
                                        return <TableCell {...column.getHeaderProps()}
                                            sx={{
                                                py: 0, borderBottom: 'unset', width: column.fixedWidth || (column.id == 'expander'
                                                    ? '56px' : (column.id == 'actions' ? '140px' : 'auto'))
                                            }}>
                                            <Typography sx={{ lineHeight: 2, fontWeight: 700 }}>
                                                {column.render('Header')}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <ArrowDown sx={{ verticalAlign: 'middle' }} />
                                                        : <ArrowUp sx={{ verticalAlign: 'middle' }} />
                                                    : null}
                                            </Typography>
                                        </TableCell>;
                                    })}
                            </TableRow>
                            {canFilter ? <TableRow {...headerGroupProps} sx={{ bgcolor: theme.palette.background.paper, boxShadow: theme.shadows[1] }}>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column => {
                                        // Apply the header cell props
                                        return <TableCell {...column.getHeaderProps()} sx={{ py: 0, borderBottom: 'unset' }}>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </TableCell>;
                                    })}
                            </TableRow> : null}
                        </Fragment>;
                    })}
                </TableHead>
                {/* Apply the table body props */}
                <TableBody {...getTableBodyProps()}>
                    {// Loop over the table rows
                        rows.map(row => {
                            // Prepare the row for display
                            prepareRow(row)

                            // Apply the row props
                            const { key, ...rowProps } = row.getRowProps();
                            return <Fragment key={key}>
                                <TableRow {...rowProps}>
                                    {// Loop over the rows cells
                                        row.cells.map(cell => {
                                            // Apply the cell props
                                            return (
                                                <TableCell {...cell.getCellProps()} sx={{
                                                    ...(expandRows ? { borderBottom: 'unset' } : {}),
                                                    ...(cell.column.id == 'actions' ? { textAlign: 'right' } : {})
                                                }}>
                                                    {// Render the cell contents
                                                        cell.render('Cell')}
                                                </TableCell>
                                            )
                                        })}
                                </TableRow>
                                {expandRows ? (
                                    <TableRow>
                                        <TableCell colSpan={visibleColumns.length} sx={{ px: 3, py: 0 }}>
                                            <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
                                                <RowDetails row={row} />
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                            </Fragment>;
                        })}
                </TableBody>
            </Table>
        </Box>
        <Toolbar sx={{
            py: 1, bgcolor: theme.palette.background.paper, flexShrink: 0, justifyContent: 'space-between',
            borderTop: '1px solid transparent', borderColor: theme.palette.grey[300],
        }}>
            <ButtonGroup variant="outlined">
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}><ChevronDoubleLeft /></Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}><ChevronLeft /></Button>
                <Button onClick={() => nextPage()} disabled={!canNextPage}><ChevronRight /></Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}><ChevronDoubleRight /></Button>
            </ButtonGroup>
            <span>
                <span>Page&nbsp;<strong>{pageIndex + 1} (Showing {(pageIndex) * pageSize + data.length} items)</strong></span>
                {/* &nbsp;&nbsp;|&nbsp;&nbsp;
                <GoToPage pageIndex={pageIndex} gotoPage={gotoPage} />  of {pageOptions?.length} */}
            </span>
            <Select label="Page Size" value={pageSize} variant="standard">
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <MenuItem key={pageSize} value={pageSize} onClick={() => setPageSize(pageSize)}>
                        Show {pageSize}
                    </MenuItem>
                ))}
            </Select>
        </Toolbar>
    </Paper>;
};

const GoToPage = ({ pageIndex, gotoPage }) => {
    const [page, setPage] = useState(pageIndex + 1);

    return <Input
        type="number"
        sx={{ maxWidth: 220 }}
        defaultValue={pageIndex + 1}
        onChange={e => setPage(Number(e?.target?.value || 0))}
        startAdornment="Page:"
        endAdornment={<Button variant="outlined" sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} onClick={() => gotoPage(page - 1)}>Go</Button>}
    />
}
