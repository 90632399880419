export const buildRoutesObjectForExport = (routes, prefix = '') => {
    const result = {};

    routes.map(route => {
        if (!route.key) { return; }

        const path = prefix + route.path;

        if (route.children) {
            result[route.key] = {
                index: path,
                ...buildRoutesObjectForExport(route.children, path + '/'),
            };
        } else {
            result[route.key] = path;
        }
    });

    return result;
};