import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { TextField, Select, DatePicker, D } from 'mui-rff';
import { Grid, MenuItem } from '@mui/material';

export default function UsersFilterForm({ ...props }) {
    // useEffect(() => {
        console.log('form props', props);
    // }, []);

    return <>
        <Grid item xs={6}>
            <TextField name="firstName" label="First Name" />
        </Grid>
        <Grid item xs={6}>
            <TextField name="lastName" label="Last Name" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.after" label="Registered After" inputFormat="yyyy-mm-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.before" label="Registered Before" inputFormat="yyyy-mm-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <Select name="hasCompletedIntro" label="Has completed intro?">
                <MenuItem value={null}>Any</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
            </Select>
        </Grid>
    </>;
};
