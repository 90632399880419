import { useCallback, useState } from 'react';
import feedbackService from '../../../_core/services/feedbackService';

export default function useFeedbackLogs(feedbackId) {
    const [logs, setLogs] = useState(false);

    const getLogs = useCallback(async () => {
        setLogs(false);
        const logs = await feedbackService.getLogs(feedbackId);
        setLogs(logs);
    }, [feedbackId]);

    return [logs, getLogs];
};
