import { Avatar, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import config from '../../../../../config';

export default function AvatarCell({ value }) {
    const src = useMemo(() => {
        return `${config.avatarHost}file/download?relativeFilePath=${encodeURIComponent(value)}&asAttatchment=false&width=150&height=150`;
    }, [value]);

    const theme = useTheme();

    return <Avatar src={src}
        sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.grey[400],
            my: -0.5,
            padding: '3px',
            width: '51px',
            height: '51px',
        }}
        imgProps={{
            sx: { borderRadius: '50%' },
        }}
    />;
};
