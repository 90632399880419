import React, { useCallback, useEffect, useRef } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { CheckAll, FilterRemove } from 'mdi-material-ui';
import { dialogConfirmAsync } from '../../dialog';
import { Form } from 'react-final-form';

export default function FilterForm({ onSubmit = () => { }, ...props }) {
    return <Form onSubmit={onSubmit} component={InnerFilterForm} {...props} />;
}

const InnerFilterForm = ({ handleSubmit, pristine, form, setCanClose, Component, ...props }) => {
    const pristineRef = useRef();
    pristineRef.current = pristine; // keep updated

    const canClose = useCallback(() => {
        return pristineRef.current || dialogConfirmAsync({
            title: 'Are you sure?',
            content: 'Changes will be lost',
            okText: 'Close',
        });
    }, []);

    const resetFilters = useCallback(async () => {
        const response = await dialogConfirmAsync({
            title: 'Are you sure?',
            content: 'All filters will be removed',
            okText: 'Clear',
        });

        if (response) {
            form.reset({});
            handleSubmit();
        }
    })

    useEffect(() => {
        setCanClose(canClose);
    }, []);

    return <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
            <Component {...{ pristine, form, ...props }} />
            <Grid item container xs={12}>
                <Button type="button" variant="outlined" onClick={resetFilters}><FilterRemove />&nbsp;Clear Filters</Button>
                <Box sx={{ flex: 1 }} />
                <Button type="submit" variant="contained"><CheckAll />&nbsp;Apply Filters</Button>
            </Grid>
        </Grid>
    </form>;
};
