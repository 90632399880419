import React, { useMemo } from 'react';
import config from '../../../../../config';

export default function CouponLinkCell({ value }) {
    const link = useMemo(() => {
        return `${config.app}registerByCoupon/${value}`;
    }, [value]);

    return value ? <a href={link} target="_blank" rel="noopener noreferrer">{value}</a> : '-';
};
