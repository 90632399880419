import AvatarCell from '../../../components/lcTable/cells/avatarCell';
import BooleanCell from '../../../components/lcTable/cells/booleanCell';

export const columns = [{
    accessor: 'imagePath',
    disableFilters: true,
    disableSortBy: true,
    Cell: AvatarCell,
    fixedWidth: '67px',
}, {
    Header: '_id',
    accessor: '_id',
}, {
    Header: 'firstName',
    accessor: 'firstName',
}, {
    Header: 'lastName',
    accessor: 'lastName',
}, {
    Header: 'email',
    accessor: 'email',
}, {
    Header: 'Intro?',
    accessor: 'hasCompletedIntro',
    Cell: BooleanCell,
}];
