import React from 'react';
import { Drawer, List, ListItem } from '@mui/material';
import { AccountConvertOutline, AccountOutline, AccountQuestionOutline, LockOpenOutline, LockOutline, MessageTextOutline, ViewDashboardOutline } from 'mdi-material-ui';
import { privateRoutes } from '../../_core/routes/routes';
import navigationStyle from './navigation.style';
import NavItem from './navItem';
import NavBox from './navBox';
import NavBoxItem from './navBoxItem';

export default function Navigation({ ...drawerProps }) {
    return <Drawer variant="permanent" {...drawerProps}>
        <List disablePadding>
            <ListItem sx={navigationStyle.menuTitle}>LC Admin</ListItem>
            <NavItem Icon={ViewDashboardOutline} label="Overview" route={privateRoutes.home} />
            <NavBox label="People">
                <NavBoxItem Icon={AccountQuestionOutline} label="Prospects" route={privateRoutes.people.prospects} />
                <NavBoxItem Icon={AccountConvertOutline} label="Subscriptions" route={privateRoutes.people.subscriptions} />
                <NavBoxItem Icon={MessageTextOutline} label="Feedback" route={privateRoutes.people.feedback} />
            </NavBox>
        </List>
    </Drawer>;
};
