import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { TextField, Select, DatePicker, D, Switches } from 'mui-rff';
import { Grid, MenuItem } from '@mui/material';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';
import { FeedbackType, TaskStatus } from '../../../_core/services/feedbackService';

export default function FeedbackFilterForm({ ...props }) {
    // useEffect(() => {
    console.log('form props', props);
    // }, []);

    return <>
        {/* <Grid item xs={6}>
            <TextField name="user" label="User" />
        </Grid> */}
        <Grid item xs={4}>
            <Select name="status" label="Status">
                {getEnumItemsAsArray(TaskStatus).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={4}>
            <Select name="feedbackType" label="Type">
                {Object.keys(FeedbackType).map((key, index) => <MenuItem key={key} value={key}>{FeedbackType[key]}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={4}>
            <Select name="rating" label="Rating">
                {[1,2,3,4,5].map(rating => <MenuItem key={rating} value={rating}>{rating}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.after" label="Added After" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.before" label="Added Before" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
    </>;
};
