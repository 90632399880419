import dev from './config.dev';
import staging from './config.staging';
import live from './config.live';

// TODO: add prelive and live environment configurations when we build them

// eslint-disable-next-line no-undef
const env = typeof (CONFIG) == 'undefined' ? 'dev' : CONFIG;

const envToConfigMap = {
    dev,
    staging,
    live,
};

const config = envToConfigMap[env] || dev;

console.log('CLIENT CONFIG:', env, config);

export default config;
