// TODO: remove this once the actual data sources are in place

// This acts like CouchDB collections with records (arrays of docs)

const repeatDocs = (arr, times = 20) => {
    let result = [...arr];

    for (let i = 0; i < times; i++) {
        result.push(...arr.map(doc => ({ ...doc, _id: `${doc._id}-${i + 1}` })));
    }

    return result;
};

const distinctUsers = [{
    dateOfBirth: "1987-01-02T02:00:00",
    firstName: "Teodor",
    lastName: "Sandu",
    timezone: "GTB Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "doru87@gmail.com",
    phone: "theo",
    imagePath: "theo\\\\teodor4e33f450-d945-4e44-9bfa-05b3603745f4.jpg",
    isInitialized: true,
    hasCompletedIntro: true,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2019-01-15T10:09:17.0725702+00:00",
    createdBy: "org.couchdb.user:theo",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:theo",
},
{
    dateOfBirth: "1982-05-26T15:00:00",
    firstName: "Tudor",
    lastName: "Dumitriu",
    timezone: "GTB Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "tudor.dumitriu@gmail.com",
    phone: "12123123123",
    imagePath: "tudor\\blob6148fc4c-94aa-40d4-b40d-94974a328d3e.png",
    isInitialized: true,
    hasCompletedIntro: true,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2018-11-13T04:19:58.0098908+00:00",
    createdBy: "org.couchdb.user:tudor",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:tudor",
},
{
    dateOfBirth: "1990-07-02T00:00:00",
    firstName: "Laura",
    lastName: "Sandu",
    timezone: "GTB Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "laura.sandu@adaptabi.com",
    phone: "0766332011",
    imagePath: "laurasandu\\blob731c2d58-b143-47a6-9bef-c20486d3b7af.png",
    isInitialized: true,
    hasCompletedIntro: false,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2019-01-15T10:27:12.8742629+00:00",
    createdBy: "org.couchdb.user:laurasandu",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:laurasandu",
},
{
    dateOfBirth: "2021-04-21T00:00:00",
    firstName: "Madalina",
    lastName: "Dumitriu",
    timezone: "GTB Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "mada.dumitriu@gmail.com",
    phone: "mada",
    imagePath: "mada\\blobc5c5dcb5-a7a6-4dd5-927a-5c57f082045c.png",
    isInitialized: true,
    hasCompletedIntro: true,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2019-03-29T11:30:09.0873944+00:00",
    createdBy: "org.couchdb.user:mada",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:mada",
},
{
    dateOfBirth: "1964-06-05T07:00:00+01:00",
    firstName: "Roger",
    lastName: "Lockwood",
    timezone: "Romance Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "roger@lifechanger.com",
    phone: "405 245 0377",
    imagePath: "Roger\\blobca804406-384f-44ad-bf86-8d450a7f5f3f.png",
    isInitialized: true,
    hasCompletedIntro: true,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2018-07-04T14:55:14.5447047+01:00",
    createdBy: "org.couchdb.user:Roger",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:Roger",
},
{
    dateOfBirth: "1962-06-03T07:00:00+01:00",
    firstName: "Deborah",
    lastName: "Lockwood",
    timezone: "GTB Standard Time",
    timeBeginMyDay: "05:00",
    timeEndMyDay: "21:00",
    sex: "Unkonwn",
    addressDetails: {},
    email: "deborah-lockwood@ouhsc.edu",
    phone: "4056206594",
    imagePath: "Deb\\blobc7bc634e-6ef8-4573-8a90-284292188d0a.png",
    isInitialized: true,
    hasCompletedIntro: true,
    fieldTypes: {},
    entityTypes: [],
    userType: "premium",
    createdAt: "2018-07-04T14:56:40.3109975+01:00",
    createdBy: "org.couchdb.user:Deb",
    updatedBy: "SYSTEM",
    updatedAt: "2022-02-17T11:20:21+02:00",
    type: "userProfileEntity",
    _id: "org.couchdb.user:Deb",
}
];

export const users = repeatDocs(distinctUsers, 50);
