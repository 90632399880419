import dateUtils from '../../utils/dateUtils';
import apiService, { ADMIN_USER, DATA_SOURCE_TYPE } from './apiService';
import userService from './userService';

export const TaskStatus = {
    Todo: 'Todo',
    Progress: 'Progress',
    Done: 'Done',
};

export const FeedbackType = {
    Opinion: 'Opinion',
    SupportTicket: 'Support Ticket',
};

const feedbackService = {
    endpoint: 'central',
    getList: async ({ pageIndex, pageSize, filters = {} }) => {
        // const listEndpoint = `${feedbackService.endpoint}/_design/feedback/_view/feedbackList?include_docs=true&reduce=false&descending=true&skip=${pageIndex}&limit=${pageSize}`,
        const listEndpoint = `${feedbackService.endpoint}/_find`,
            selector = {};

        selector.status = filters.status || { '$exists': true }; // catch-all / wildcard
        selector.feedbackType = filters.feedbackType || { '$exists': true }; // catch-all / wildcard
        selector.rating = typeof (filters.rating) != 'undefined' ? filters.rating : { '$exists': true }; // catch-all / wildcard
        if (filters.createdAt && (filters.createdAt.after || filters.createdAt.before)) {
            selector.createdAt = {};
            if (filters.createdAt.after) {
                selector.createdAt.$gte = dateUtils.toServerString(filters.createdAt.after);
            }
            if (filters.createdAt.before) {
                selector.createdAt.$lte = dateUtils.toServerString(filters.createdAt.before);
            }
        } else {
            selector.createdAt = { '$exists': true }; // catch-all / wildcard
        }

        let payload = {
            selector,
            limit: pageSize,
            skip: pageIndex * pageSize,
            sort: [{ createdAt: 'desc' }],
            use_index: ["_design/ecac4ba9ab22a362684457a4d04c1535fafcbf25", "feedbackIndex"],
        },
            data = await apiService.post(listEndpoint, payload);

        if (data && data.docs) {
            data = data.docs;

            let total = data.length < pageSize ? data.length : pageSize * (pageIndex + 1) + 1;

            return {
                data,
                pagination: {
                    filtered: total, // filtered is what the table shows at the bottom (page count is computed based on this). It may be equal to total if no filters are applied, that's ok
                },
            };
        }

        // If you want to display a custom message, return this:
        // return {
        //     ok: false,
        //     message: 'Custom error message',
        // };
        // Otheriwse, standard fail message will be shown
    },
    getLogs: async feedbackId => {
        return apiService.get('/admin/feedbacklogs?id=' + feedbackId, DATA_SOURCE_TYPE.API);
    },
    // getItem: async (id) => {
    //     let endpoint = `${subscriptionsService.endpoint}/${id}`,
    //         response = await apiService.get(endpoint);
    //     if (!response || response.error) {
    //         response = {
    //             ok: false,
    //             message: "Could not update the prospect",
    //         }
    //     } else {
    //         response = {
    //             ok: true,
    //             data: response,
    //         }
    //     }
    //     return response;
    // },
    updateItem: async (feedback) => {
        let response = await apiService.post(feedbackService.endpoint, feedback);
        if (!response) {
            response = {
                ok: false,
                message: "Could not update the feedback",
            };
        }
        return {
            ok: true,
            data: response,
        };
    }
};

export default feedbackService;
