import React, { useCallback, useState } from 'react';
import { columns } from './users.config';
import LCTable from '../../../components/lcTable/lcTable';
import { openEditPopupAsync } from '../../../components/popup/editPopup';
import { dialogConfirmAsync } from '../../../components/dialog';
import progress from '../../../components/modalProgress/progressController';
import UserForm from './userForm';
import UsersFilterForm from './usersFilterForm';
import userService from '../../../_core/services/userService';

// This is used in Edit popup header AND the Delete confirmation dialog
const RowDisplayName = ({ row }) => {
    const { firstName, lastName, email } = row?.original || {};
    return firstName || lastName || email ? <>{firstName} {lastName} <strong>({email})</strong></> : <strong>Unnamed</strong>;
}

export default function UsersPage() {
    const getData = userService.getList;

    const onAddRow = useCallback(async () => {
        const item = await openEditPopupAsync({
            Content: UserForm,
            title: 'Add New',
        });
        console.log('response', item);
        if (!item) { return; }

        //const result = await insertItem(item);
        const result = await progress.wrap(async () => await sleep(1000));

        return result;
    }, []);

    const onEditRow = useCallback(async row => {
        console.log('edit', row);

        const item = await openEditPopupAsync({
            title: <RowDisplayName row={row} />,
            Content: UserForm,
            initialValues: row?.original || {},
        });
        console.log('response', item);
        if (!item) { return; }

        //const result = await updateItem(item);
        const result = await progress.wrap(async () => await sleep(1000));

        return result;
    }, []);

    const onDeleteRow = useCallback(async row => {
        console.log('delete', row);

        const confirmed = await dialogConfirmAsync({
            title: 'Are you sure?',
            content: <>DELETING <RowDisplayName key={2} row={row} /></>,
        });
        if (!confirmed) { return; }

        //const result = await deleteItem(item);
        const result = await progress.wrap(async () => await sleep(1000));

        return result;
    }, []);

    return <LCTable columns={columns}
        FiltersForm={UsersFilterForm}
        getData={getData}
        onAddRow={onAddRow}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
    />;
};
