import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import navigationStyle from './navigation.style';

export default function NavItem({ Icon, label, route }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return <ListItem sx={{ ...navigationStyle.item, ...navigationStyle.itemCategory, cursor: 'pointer' }} onClick={() => navigate(route)} selected={pathname == route}>
        <ListItemIcon><Icon /></ListItemIcon>
        <ListItemText>{label}</ListItemText>
    </ListItem>;
};
