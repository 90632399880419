import config from '../../../config';
import storage from './storage';

let logs = [],
    firstLog = true;

const settings = {
    guestUsername: 'Guest', // label for user while not logged in
    logsLimit: 300, // limit log size to this many entries (delete oldest message on new message received)
    maxLogVariableLength: 4096, // limit object serialization to a certain string length (to avoid too long strings)
};

class Logger {
    username = settings.guestUsername;

    setUsername = username => this.username = username;

    clearUsername = () => this.username = settings.guestUsername;

    getPrefix = type => `[${type}::${new Date().toISOString()}::${this.username}]`;

    info = (...params) => {
        const prefix = this.getPrefix('INFO');
        console.info(prefix, ...params);
        this.record(prefix, ...params);
    };

    log = (...params) => {
        if (firstLog) {
            this.record('---------------------------------------------------------------- Page Load ----------------------------------------------------------------');
            firstLog = false;
        }
        const prefix = this.getPrefix('LOG');
        console.log(prefix, ...params);
        this.record(prefix, ...params);
    };

    warn = (...params) => {
        const prefix = this.getPrefix('WARN');
        console.warn(prefix, ...params);
        this.record(prefix, ...params);
    };

    error = (...params) => {
        this.errorWithoutSend(...params);
        this.sendLogs();
    };

    errorWithoutSend = (...params) => {
        const prefix = this.getPrefix('ERROR');
        console.error(prefix, ...params);
        this.record(prefix, ...params);
    };

    getLogs = () => [...logs];

    sendLogs = () => {
        // return fetch(config.host + 'logger', {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ logs, username: this.username }),
        // }).catch(() => {
        //     console.error('Cannot send logs to server!', config.host, logs);
        // });
    }

    clearLogs = () => {
        logs = [];
        storage.save('logger', []);
    };

    sendReportAsync = async content => {
        const prefix = this.getPrefix('ERROR');
        this.record(prefix, '[ISSUE REPORT]', content);

        try {
            const response = await this.sendLogs();

            if (response.ok || response.status == 200) {
                return { success: true };
            } else {
                const text = await response.text();
                this.errorWithoutSend('Sending issue report failed', response.status, text);
                return { success: false, error: text || 'Unknown error' };
            }
        } catch (error) {
            return { success: false, error };
        }
    }

    record = (prefix, ...params) => {
        logs.unshift(prefix + ' ' + this.stringifyParams(...params));

        if (logs.length > settings.logsLimit) {
            logs.pop();
        }

        storage.save('logger', logs);
    };

    stringifyParams = (...params) => {
        const strings = params.map(param => {
            let paramType = Array.isArray(param) ? 'array' : typeof (param);

            switch (paramType) {
                case 'string': return param;
                case 'undefined': return paramType;
                default: {
                    let stringParam = ' ';

                    try {
                        stringParam = JSON.stringify(param);
                    } catch (error) {
                        try {
                            stringParam = param && param.toString ? `${param}` : `[${paramType}]`;
                        } catch (error) {
                            stringParam = '[Unidentified param]';
                        }
                    }

                    if (stringParam.length > settings.maxLogVariableLength) {
                        stringParam = stringParam.substr(0, settings.maxLogVariableLength) + '...';
                    }

                    return `[${paramType}: ${stringParam}]`;
                }
            }
        });

        return `${strings.join(' ')}`;
    };

    // this is used by Cordova Plugin managers to provide alternative member functions when the native plugin is not available
    override = (msg, obj, ...fns) => {
        this.log(msg);
        let log = this.log.bind(this, msg);
        fns.map(fn => obj[fn] = log);
    };
};

const logger = new Logger();

window.logger = logger;

window.onerror = (msg, url, lineNo, columnNo, error) => {
    let string = msg.toLowerCase();

    if (string.indexOf('script error') > -1) {
        logger.error('Script Error. See browser console for details');
    } else {
        let message = [
            'Message: ' + msg,
            'URL: ' + url,
            'Line: ' + lineNo,
            'Column: ' + columnNo,
            'Stack: ' + error.stack,
        ].join(' - ');

        logger.error('GLOBAL error', message, error);
    }

    return false;
};

window.addEventListener('unhandledrejection', message => logger.error('GLOBAL promise error', message.reason, message));

(storage.query('logger') || (new Promise(resolve => resolve([])))).then(savedLogs => {
    logs.push(...(savedLogs || []));

    if (logs.some(log => log.startsWith('[ERROR::'))) {
        logger.sendLogs().then(logger.clearLogs);
    }
});

export default logger;
