import React, { useCallback, useRef, useState } from 'react';
import { columns } from './prospects.config';
import LCTable from '../../../components/lcTable/lcTable';
import { openEditPopupAsync } from '../../../components/popup/editPopup';
import { dialogConfirmAsync, dialogOpenAsync } from '../../../components/dialog';
import progress from '../../../components/modalProgress/progressController';
import ProspectsFilterForm from './ProspectsFilterForm';
import prospectsService from '../../../_core/services/prospectsService';
import ProspectForm from './prospectForm';
import { popupClose, popupOpen, popupUpdate } from '../../../components/popup/popup';
import { AccountOutline, Plus } from 'mdi-material-ui';
import SubscriptionForm from '../subscriptions/subscriptionForm';
import subscriptionsService from '../../../_core/services/subscriptionsService';
import RowDetailsJson from '../../../components/lcTable/rowDetailsJson';
import { Button, IconButton } from '@mui/material';
import ProspectBatchAddForm from './prospectAddBatchForm';

// This is used in Edit popup header AND the Delete confirmation dialog
const RowDisplayName = ({ row }) => {
    const { firstName, lastName, email } = row?.original || {};
    return firstName || lastName || email ? <>{firstName} {lastName} <strong>({email})</strong></> : <strong>Unnamed</strong>;
}

export default function ProspectsPage() {
    const getData = prospectsService.getList;
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    const generateCode = useCallback(async (programme) => {
        let length = 10,
            text = '';
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return `${programme}-${text}`;
    }, []);

    var reloadCallback = null;
    const setReloadCallback = fn => { reloadCallback = fn };

    const onAddRow = useCallback(async () => {
        const item = await openEditPopupAsync({
            initialValues: {
                freePeriod: 9,
            },
            Content: ProspectForm,
            title: 'Add New',
        });
        console.log('response', item);
        if (!item) { return; }

        const result = await progress.wrap(() => prospectsService.addItem(item));

        return result;
    }, []);

    const onEditRow = useCallback(async row => {
        console.log('edit', row);
        const initialValues = row?.original || {},
            item = await openEditPopupAsync({
                title: <RowDisplayName row={row} />,
                Content: ProspectForm,
                initialValues,
                generateCode,
            });
        console.log('response', item);
        if (!item) { return; }

        const result = await progress.wrap(() => prospectsService.updateItem(item));
        return result;
    }, []);

    const onDeleteRow = useCallback(async row => {
        console.log('delete', row);
        const item = row?.original || {};

        if (item.userId) {
            await dialogOpenAsync('This coupon cannot be deleted because is already used!');
            return;
        }

        const confirmed = await dialogConfirmAsync({
            title: 'Are you sure?',
            content: <>DELETING <RowDisplayName key={2} row={row} /></>,
        });
        if (!confirmed) { return; }

        const result = await progress.wrap(() => prospectsService.deleteItem(item));

        return result;
    }, []);

    const onViewSubscription = useCallback(row => async () => {
        console.log('delete', row);
        const item = row?.original || {};

        if (!item.userId) {
            await dialogOpenAsync('This coupon is not used');
            return;
        }
        progress.show();
        const subscriptionResponse = await subscriptionsService.getItem(item.userId);
        progress.hide();
        let subscription = { empty: null };
        if (subscriptionResponse && subscriptionResponse.ok) {
            subscription = subscriptionResponse.data;
        }
        item = await openEditPopupAsync({
            title: <div>{subscription._id}</div>,
            Content: () => <RowDetailsJson row={{ original: subscription }} />,
        });
    }, []);

    const onBatchAddRows = useCallback(async () => {
        const batchAddDetails = await openEditPopupAsync({
            initialValues: {
                freePeriod: 9,
            },
            Content: ProspectBatchAddForm,
            title: 'Add Batch',
        });
        console.log('response', batchAddDetails);
        if (!batchAddDetails) { return; }

        const result = await progress.wrap(() => prospectsService.addBatchItems(batchAddDetails));
        if (reloadCallback) {
            reloadCallback();
        }
        return result;

    }, []);

    return <LCTable columns={columns}
        FiltersForm={ProspectsFilterForm}
        getData={getData}
        onAddRow={onAddRow}
        onEditRow={onEditRow}
        onDeleteRow={onDeleteRow}
        getRowActions={row => <IconButton onClick={onViewSubscription(row)}><AccountOutline /></IconButton>}
        headerActions={<Button onClick={onBatchAddRows} color="inherit" sx={{ ml: 2 }}><Plus />&nbsp;Add Batch</Button>}
        setReloadCallback={setReloadCallback}
    />;
};
