import React from 'react';
import { TextField, Select, DatePicker, Switches } from 'mui-rff';
import { Grid, MenuItem } from '@mui/material';
import { SubscriptionPaymentSource, SubscriptionSource, UserType } from '../../../_core/services/subscriptionsService';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';

export default function SubscriptionsFilterForm({ }) {
    return <>
        <Grid item xs={6}>
            <TextField name="_id" label="ID" />
        </Grid>
        <Grid item xs={6}>
            <Select name="userType" label="User Type">
                {getEnumItemsAsArray(UserType).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select name="subscriptionSource" label="Source">
                {getEnumItemsAsArray(SubscriptionSource).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={6}>
            <Select name="subscriptionPaymentSource" label="Payment Source">
                {getEnumItemsAsArray(SubscriptionPaymentSource).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={6}>
            <TextField name="programme" label="Programme" />
        </Grid>
        <Grid item xs={6}>
            <Switches disabled
                name="isActive"
                data={{ label: 'Is Active' }}
            />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="endDate.after" label="Ends After" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="endDate.before" label="Ends Before" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.after" label="Registered After" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.before" label="Registered Before" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
    </>;
};
