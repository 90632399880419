import logger from '../../utils/logger';

class DataService {
    currentSession = null;

    // this will get called by authService on login, and awaited to complete until the new user's interface is ready to display
    onLogin = async session => {
        // session.user comes from our API, with the DB record
        this.currentSession = session;
        this.userId = session.user._id;

        // connect to whatever we need
        await this.initDB();

        logger.log('[DataService::onLogin] Initialized.', this.userId);
    };

    // this will get called by authService at the START of the logout process, and awaited to clear up everything until a new user can login
    onLogout = async () => {
        this.currentSession = null;

        logger.log('[DataService::onLogout] Done.');
    };

    initDB = async () => {
        // ...
    };
}

export default window.dataService = new DataService();
