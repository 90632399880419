import React from 'react';
import { Grid, Typography, MenuItem, Button } from '@mui/material';
import { TextField, Select } from 'mui-rff';
import { Field } from 'react-final-form';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';
import { TaskStatus } from '../../../_core/services/feedbackService';
import apiService from '../../../_core/services/apiService';
import useFeedbackLogs from './useFeedbackLogs';

export default function FeedbackForm({ form, values, getReply, ...props }) {
    const [logs, getLogs] = useFeedbackLogs(values && values._id);
    let stringifiedLogs = logs;
    if (stringifiedLogs) {
        stringifiedLogs = JSON.stringify(stringifiedLogs);
    }

    return <Grid container spacing={3} sx={{ px: 2, pt: 3, pb: 1 }}>
        <Grid item container xs={12}>
            <Field name="_id" component={({ input: { value } }) => <Typography variant="caption" color="text.disabled">{`ID:${value}`}</Typography>} />
            &nbsp;
            <Field name="createdBy" component={({ input: { value } }) => <Typography variant="caption" color="text.disabled">{`UserID:${value}`}</Typography>} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="message" label="Message" multiline minRows={1} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <Select name="status" label="Status">
                {getEnumItemsAsArray(TaskStatus).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField name="reply" label="Reply" multiline minRows={2} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <div><b>HISTORY</b></div>
            <Field name="history" component={({ input: { value } }) => { return (value || []).sort((x, y) => x.createdAt < y.createdAt ? 1 : x.createdAt > y.createdAt ? -1 : 0).map(h => <div key={h._id}><pre>{h.text}</pre><span>{h.createdBy} ({h.createdAt})</span></div>) }} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <div><b>IMAGES</b></div>
            <Field name="images" component={({ input: { value } }) => { return (value || []).map((h, i) => <a key={i} href={apiService.getImageURL(h, true)} target='_blank'><img src={apiService.getImageURL(h)} style={{ width: 100, height: 100, margin: 10 }} ></img></a>) }} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <div><b>LOGS IN DB</b></div>
            <Field name="logs" component={({ input: { value } }) => <Typography variant="caption" color="text.disabled">{`${value}`}</Typography>} />
        </Grid>
        {values._id ? <Grid item xs={12} sm={12}>
            <div><b>SERVER LOGS</b></div>
            {logs === false ? null : <Typography variant="caption" color="text.disabled">{stringifiedLogs}</Typography>}
            <Button onClick={getLogs}>Get Logs{logs === false ? '' : ' Again'}</Button>
        </Grid> : null}
    </Grid>;
};
