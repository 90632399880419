import AvatarCell from '../../../components/lcTable/cells/avatarCell';
import BooleanCell from '../../../components/lcTable/cells/booleanCell';
import DateTimeCell from '../../../components/lcTable/cells/dateTimeCell';

export const columns = [{
    Header: 'ID',
    accessor: '_id',
}, {
    Header: 'Name',
    accessor: 'name',
}, {
    Header: 'Email',
    accessor: 'email',
}, {
    Header: 'Type',
    accessor: 'userType',
}, {
    Header: 'Source',
    accessor: 'subscriptionSource',
}, {
    Header: 'Payment Source',
    accessor: 'subscriptionPaymentSource',
}, {
    Header: 'Programme',
    accessor: 'programme',
    // }, {
    //     Header: 'Active',
    //     accessor: 'isActive',
    //     Cell: BooleanCell,
}, {
    Header: 'End',
    accessor: 'endDate',
    Cell: DateTimeCell,
}, {
    Header: 'Created',
    accessor: 'createdAt',
    Cell: DateTimeCell,
}];
