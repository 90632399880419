import React from 'react';
import { Divider, ListItem, ListItemText, Box } from '@mui/material';

export default function NavBox({ label, children }) {
    return <Box sx={{ bgcolor: '#101F33' }}>
        <ListItem sx={{ py: 2, px: 3 }}>
            <ListItemText sx={{ color: 'common.white' }}>{label}</ListItemText>
        </ListItem>
        {children}
        <Divider sx={{ mt: 2 }} />
    </Box>
};
