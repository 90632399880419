import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AccountOutline, BellOutline, ChevronRight, DotsVertical, InformationOutline, Logout, Menu as MenuIcon } from 'mdi-material-ui';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { privateRoutes, privateRoutesDefinition } from '../_core/routes/routes';
import { useLocation, useNavigate } from 'react-router';
import { useUserProfile } from '../_core/contexts/userProfileContext';
import authService from '../_core/services/authService';
import { Divider, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';

const menuStyle = {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
};

const menuItemIconStyle = {
    minWidth: 32,
    width: 32,
    marginLeft: -4,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
};

function Header(props) {
    const { onDrawerToggle } = props;

    const navigate = useNavigate();

    const location = useLocation();

    const breadcrumbs = useBreadcrumbs(privateRoutesDefinition);
    if (breadcrumbs.length > 1) {
        breadcrumbs.shift();
    }
    // console.warn('Header', location.pathname.substring(1).split('/'), breadcrumbs);

    const menuIconRef = useRef();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const openMenu = useCallback(() => setMenuIsOpen(true), []);
    const closeMenu = useCallback(() => setMenuIsOpen(false), []);

    const menuAction = useCallback((fn = () => { }) => () => (closeMenu(), fn()), []);

    const nav = useCallback(route => menuAction(() => navigate(route)));

    const logout = useCallback(menuAction(authService.logout), []);

    const { imageUrl, username = '' } = useUserProfile();

    const { userTitle, userLetters } = useMemo(() => {
        return {
            userTitle: "You're logged in as " + username,
            userLetters: (username || '').split(' ').filter(s => s && s.length).slice(0, 2).map(s => s.substr(0, 1).toUpperCase()).join(''),
        };
    }, [username]);

    return <>
        <AppBar color="primary" position="sticky" elevation={0}>
            <Toolbar>
                <Grid container spacing={1} alignItems="center">
                    <Grid sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }} item>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onDrawerToggle}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Tooltip title="No alerts">
                            <IconButton color="inherit">
                                <BellOutline />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={userTitle}>
                            <IconButton onClick={nav(privateRoutes.account)} aria-label="more" aria-haspopup="true" color="inherit">
                                <Avatar src={imageUrl} title={userTitle}>{userLetters}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <IconButton ref={menuIconRef} onClick={openMenu} aria-label="more" aria-haspopup="true" color="inherit">
                            <DotsVertical />
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <AppBar
            component="div"
            color="primary"
            position="static"
            elevation={0}
            sx={{ zIndex: 0 }}
        >
            <Toolbar>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                        <Typography variant="h5" component="h1">
                            {breadcrumbs.map(({ breadcrumb, ...rest }, key) => {
                                const crumb = <span key={key} style={{ verticalAlign: 'middle' }}>{breadcrumb}</span>;
                                return (key < breadcrumbs.length - 1
                                    ? <Typography key={key} variant="subtitle1" component="span">{crumb}<ChevronRight sx={{ verticalAlign: 'middle' }} /></Typography>
                                    : crumb);
                            })}
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        <Menu
            anchorEl={menuIconRef.current}
            open={menuIsOpen}
            onClose={closeMenu}
            keepMounted
            PaperProps={{
                elevation: 0,
                sx: menuStyle,
            }}
            sx={{ py: 0 }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={nav(privateRoutes.account)}>
                <ListItemIcon style={menuItemIconStyle}><AccountOutline /></ListItemIcon>
                My Account
            </MenuItem>
            <MenuItem onClick={nav(privateRoutes.about)}>
                <ListItemIcon style={menuItemIconStyle}><InformationOutline /></ListItemIcon>
                About
            </MenuItem>
            <Divider />
            <MenuItem onClick={logout}>
                <ListItemIcon style={menuItemIconStyle}><Logout /></ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    </>;
}

Header.propTypes = {
    onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;