import React from 'react';
import { Grid, Typography, Checkbox, MenuItem, FormControlLabel, Button } from '@mui/material';
import { TextField, Select, DatePicker, Switches } from 'mui-rff';
import { Field } from 'react-final-form';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';
import { UserType } from '../../../_core/services/subscriptionsService';

export default function ProspectBatchAddForm({ ...props }) {
    // console.log('Content props', props);
    return <Grid container spacing={3} sx={{ px: 2, pt: 1, pb: 3 }}>
        <Grid item xs={12} sm={6}>
            <TextField name="qty" label="# Prospects" type="number"/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="email" label="Coach Email" />
        </Grid>     
        <Grid item xs={12} sm={6}>
            <TextField name="programme" label="Programme" />
        </Grid>
        <Grid item xs={12} sm={6}>
            <Select name="userType" label="User Type">
                {getEnumItemsAsArray(UserType).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="freePeriod" label="# Free Months" type="number" />
        </Grid>
    </Grid>;
};
