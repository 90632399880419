import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import logger from '../../utils/logger';
import { online$ } from '../subjects';

const networkStateSubject$ = new BehaviorSubject('none');
export const networkState$ = networkStateSubject$.pipe(distinctUntilChanged());

window.Connection = {
    UNKNOWN: 'unknown',
    ETHERNET: 'ethernet',
    WIFI: 'wifi',
    CELL_2G: '2g',
    CELL_3G: '3g',
    CELL_4G: '4g',
    CELL: 'cellular',
    NONE: 'none',
};

class NetworkService {
    initialize() {
        if (window.navigator && typeof (navigator.onLine) != 'undefined') {
            window.addEventListener('offline', this.onOffline, { passive: true });
            window.addEventListener('online', this.onOnline, { passive: true });
            this.checkNetwork();
            logger.log('[NetworkService] Initialized');
        } else {
            logger.warn('[NetworkService] NOT AVAILABLE', window.navigator);
        }
    }

    onOffline = () => {
        online$.next(false);
        navigator.connection && networkStateSubject$.next(navigator.connection.type);
    }

    onOnline = () => {
        online$.next(true);
        navigator.connection && networkStateSubject$.next(navigator.connection.type);
    }

    checkNetwork = () => {
        if (window.navigator && navigator.onLine) {
            this.onOnline();
        } else {
            if (window.navigator && navigator && navigator.connection) {
                if ([Connection.UNKNOWN, Connection.NONE].includes(navigator.connection.type)) {
                    this.onOffline();
                } else {
                    this.onOnline();
                }
            }
        }
    }
};

const networkService = new NetworkService();

export default networkService;
