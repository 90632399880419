import config from '../../../../config';
import logger from '../../utils/logger';

const GET = 'GET';
const POST = 'POST';
const PATCH = 'PATCH';
const PUT = 'PUT';
const DELETE = 'DELETE';

export const DATA_SOURCE_TYPE = {
    API: 1,
    DB: 2,
}

export const ADMIN_USER = 'Admin'

export const getUrlEncodedValue = value => value ? encodeURIComponent(value) : ''

class ApiService {
    get = (endpoint = '', dataSourceType = DATA_SOURCE_TYPE.DB) => this.fetchJSON(GET, dataSourceType, endpoint);
    post = (endpoint = '', jsonBody = {}, dataSourceType = DATA_SOURCE_TYPE.DB) => this.fetchJSON(POST, dataSourceType, endpoint, jsonBody);
    patch = (endpoint = '', jsonBody = {}, dataSourceType = DATA_SOURCE_TYPE.DB) => this.fetchJSON(PATCH, dataSourceType, endpoint, jsonBody);
    put = (endpoint = '', jsonBody = {}, dataSourceType = DATA_SOURCE_TYPE.DB) => this.fetchJSON(PUT, dataSourceType, endpoint, jsonBody);
    delete = (endpoint = '', jsonBody = {}, dataSourceType = DATA_SOURCE_TYPE.DB) => this.fetchJSON(DELETE, dataSourceType, endpoint, jsonBody);
    fetchJSON = async (method = GET, dataSourceType = DATA_SOURCE_TYPE.DB, endpoint = '', jsonBody) => {
        // strip leading slashes, config.host already has the slash
        while (endpoint[0] == '/') {
            endpoint = endpoint.substr(1);
        }

        const host = dataSourceType == DATA_SOURCE_TYPE.DB ? config.db : config.api,
            url = host + endpoint;
        logger.log('[ApiService::fetchJSON]', method, url);

        try {
            let response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(jsonBody),
                credentials: 'include',
            });

            let json = null;

            try {
                json = await response.json();
            } catch (err) {
                logger.warn('[ApiService::fetchJSON] Response is not valid JSON:', err);
            } finally {
                json = json || response;
            }

            return json;
        } catch (err) {
            logger.warn('[ApiService::fetchJSON] Request failed:', err);
            return null;
        }
    }
    getImageURL = (imageRelativePath, asAttachment = false) => `${config.api}file/download?relativeFilePath=${getUrlEncodedValue(imageRelativePath)}&asAttatchment=${asAttachment}`
};

export default window.apiService = new ApiService();
