import 'whatwg-fetch';
import logger from './utils/logger';

import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-300-italic.css';

import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-400-italic.css';

import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-500-italic.css';

import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto/latin-700-italic.css';

import '../scss/index.scss';

import renderApp from './_core/app';
import authService from './_core/services/authService';
import networkService from './_core/services/networkService';

logger.log('App STARTUP');

const startup = async () => {
    networkService.initialize();

    logger.log('[startup] checking login status');
    try {
        await authService.verifyExistingLogin();
    } catch (e) {
        console.warn(e); // no, not logger here
    }

    logger.log('[startup] rendering app');
    renderApp();

    setTimeout(() => window.hideInitialLoader && window.hideInitialLoader(), 200);
};

startup();
