import dateUtils from '../../utils/dateUtils';
import stringToHex from '../../utils/stringToHex';
import apiService, { ADMIN_USER, DATA_SOURCE_TYPE } from './apiService';

export const UserType = {
    any: null,
    standard: 'standard',
    premium: 'premium',
    pro: 'pro',
};

export const SubscriptionSource = {
    any: null,
    iOS: 'iOS',
    Android: 'Android',
    Web: 'web',
};

export const SubscriptionPaymentSource = {
    any: null,
    coupon: 'coupon',
    store: 'store',
};

export const COUCH_USERID_PREFIX = 'org.couchdb.user:';

const subscriptionsService = {
    endpoint: 'subscriptions',
    subscriptionsLimitsEndpoint: 'subscriptions-limits',
    centralEndpoint: 'central',
    subscriptionDocId: 'u_subscription',
    getCurrentCampaign: async () => {
        let campaignResponse = await apiService.get('/admin/campaign', DATA_SOURCE_TYPE.API);
        if (campaignResponse && campaignResponse.success) {
            return campaignResponse.data;
        }
        return null;
    },
    getUserNameFromUserId: userId => {
        if (userId.indexOf(COUCH_USERID_PREFIX) == 0) {
            return userId.substring(COUCH_USERID_PREFIX.length);
        }
        else {
            return userId
        }
    },
    getUserDbName: username => {
        let dbName = 'userdb-' + stringToHex(username);
        return dbName;
    },
    getList: async ({ pageIndex, pageSize, filters }) => {
        const listEndpoint = `${subscriptionsService.endpoint}/_find`,
            selector = {};

        if (filters._id) {
            selector._id = { "$regex": `(.*${filters._id}.*)` };
        }
        if (filters.programme) {
            selector.programme = { "$regex": `(.*${filters.programme}.*)` };
        }
        if (filters.userType) {
            selector.userType = { '$eq': filters.userType }; // $gte = startsWith
        }
        if (filters.subscriptionSource) {
            selector.subscriptionSource = { '$eq': filters.subscriptionSource }; // $gte = startsWith
        }
        if (filters.subscriptionPaymentSource) {
            selector.subscriptionPaymentSource = { '$eq': filters.subscriptionPaymentSource }; // $gte = startsWith
        }
        if (filters.endDate) {
            selector.endDate = {};
            if (filters.endDate.after) {
                selector.endDate.$gte = dateUtils.toServerString(filters.endDate.after);
            }
            if (filters.endDate.before) {
                selector.endDate.$lte = dateUtils.toServerString(filters.endDate.before);
            }
        }
        if (filters.createdAt) {
            selector.createdAt = {};
            if (filters.createdAt.after) {
                selector.createdAt.$gte = dateUtils.toServerString(filters.createdAt.after);
            }
            if (filters.createdAt.before) {
                selector.createdAt.$lte = dateUtils.toServerString(filters.createdAt.before);
            }
        }

        let payload = {
            selector,
            limit: pageSize,
            skip: pageIndex * pageSize,
            sort: [
                {
                    "createdAt": "desc"
                }
            ]
        },
            data = await apiService.post(listEndpoint, payload);

        if (data && data.docs) {
            data = data.docs;

            let ids = data.map(d => d._id);
            let userProfileDocsResponse = await apiService.post(`${subscriptionsService.centralEndpoint}/_all_docs?include_docs=true`, { keys: ids });
            if (userProfileDocsResponse && userProfileDocsResponse.rows) {
                userProfileDocsResponse.rows.map(userProfileResponse => {
                    let subscription = data.find(s => s._id == userProfileResponse.id);
                    if (subscription && userProfileResponse.doc) {
                        subscription.email = userProfileResponse.doc.email;
                        subscription.name = `${userProfileResponse.doc.firstName} ${userProfileResponse.doc.lastName}`;
                    }
                })
            }

            let total = data.length < pageSize ? data.length : pageSize * (pageIndex + 1) + 1;
            return {
                data,
                pagination: {
                    filtered: total, // filtered is what the table shows at the bottom (page count is computed based on this). It may be equal to total if no filters are applied, that's ok
                },
            };
        }

        // If you want to display a custom message, return this:
        // return {
        //     ok: false,
        //     message: 'Custom error message',
        // };
        // Otheriwse, standard fail message will be shown
    },
    getItem: async (id) => {
        let endpoint = `${subscriptionsService.endpoint}/${id}`,
            response = await apiService.get(endpoint);
        if (!response || response.error) {
            response = {
                ok: false,
                message: "Could not update the subscription",
            }
        } else {
            response = {
                ok: true,
                data: response,
            }
        }
        return response;
    },
    updateItem: async (subscription, hasUserTypeChanged) => {
        let response = await apiService.post(subscriptionsService.endpoint, subscription);
        if (!response) {
            response = {
                ok: false,
                message: "Could not update the subscription",
            };
        }
        if (response.ok && hasUserTypeChanged) {
            //we are updating the user profile as well because these 2 values need to be kept in sync
            let userDbEndpoint = subscriptionsService.getUserDbName(subscriptionsService.getUserNameFromUserId(subscription._id)),
                userDBDocEndpoint = `${userDbEndpoint}/${subscriptionsService.subscriptionDocId}`,
                userSubscription = await apiService.get(userDBDocEndpoint);
            if (userSubscription) {
                let subscriptionsLimitsDocEndpoint = `${subscriptionsService.subscriptionsLimitsEndpoint}/${subscription.subscriptionLimitId}`,
                    subscriptionLimitDoc = await apiService.get(subscriptionsLimitsDocEndpoint);

                userSubscription.subscriptionLimitId = subscription.subscriptionLimitId;
                userSubscription.views = subscriptionLimitDoc.views;
                userSubscription.sublistsLevel = subscriptionLimitDoc.sublistsLevel;
                userSubscription.lists = subscriptionLimitDoc.lists;
                userSubscription.tasksPerList = subscriptionLimitDoc.tasksPerList;
                userSubscription.images = subscriptionLimitDoc.images;
                userSubscription.friends = subscriptionLimitDoc.friends;
                userSubscription.habits = subscriptionLimitDoc.habits;
                userSubscription.tags = subscriptionLimitDoc.tags;
                userSubscription.canCallContacts = subscriptionLimitDoc.canCallContacts;
                userSubscription.canTextContacts = subscriptionLimitDoc.canTextContacts;
                userSubscription.dailyInspirationSlides = subscriptionLimitDoc.dailyInspirationSlides;
                userSubscription.dailyInspirationTracks = subscriptionLimitDoc.dailyInspirationTracks;
                userSubscription.productivityZones = subscriptionLimitDoc.productivityZones;
                userSubscription.canEditTimeBlocks = subscriptionLimitDoc.canEditTimeBlocks;
                userSubscription.notifications = subscriptionLimitDoc.notifications;

                userSubscription.userType = subscription.userType;
                userSubscription.subscriptionEndDate = subscription.endDate;
                //userSubscription.updatedBy = ADMIN_USER;
                userSubscription.updatedAt = dateUtils.toServerString(new Date());
                response = await apiService.put(userDBDocEndpoint, userSubscription);
                if (!response) {
                    response = {
                        ok: false,
                        message: "Could not update the user subscription",
                    };
                }
            }
        }
        return response;
    }
};

export default subscriptionsService;
