import { users } from './dummyData';
import dateUtils from '../../utils/dateUtils';

const userService = {
    centralEndpoint: 'central',
    getList: async ({ pageIndex, pageSize, filters }) => {
        const selector = {};

        if (filters.firstName) {
            selector.firstName = { '$gte': filters.firstName }; // $gte = startsWith
        }
        if (filters.lastName) {
            selector.lastName = filters.lastName; // direct assignment = exact match
        }
        if (filters.email) {
            selector.email = { '$gte': filters.email };
        }
        if (filters.hasCompletedIntro != null) {
            selector.hasCompletedIntro = filters.hasCompletedIntro;
        }
        if (filters.createdAt) {
            selector.createdAt = {};
            if (filters.createdAt.after) {
                selector.createdAt.$gte = dateUtils.toServerString(filters.createdAt.after);
            }
            if (filters.createdAt.before) {
                selector.createdAt.$lte = dateUtils.toServerString(filters.createdAt.before);
            }
        }

        // TODO: call appropriate endpoint via apiService and pass paging and selector as payload

        // this is just for the demo page to work
        const filtered = users.filter(user => {
            if (filters.firstName && !user.firstName?.startsWith(filters.firstName)) { return false; }
            if (filters.lastName && !user.lastName?.startsWith(filters.lastName)) { return false; }
            if (filters.email && !user.email?.startsWith(filters.email)) { return false; }
            if (filters.hasCompletedIntro != null && user.hasCompletedIntro != filters.hasCompletedIntro) { return false; }
            if (filters.createdAt) {
                if (filters.createdAt.after && user.createdAt < dateUtils.toServerString(filters.createdAt.after)) { return false; }
                if (filters.createdAt.before && user.createdAt > dateUtils.toServerString(filters.createdAt.before)) { return false; }
            }
            return true;
        });

        return {
            data: filtered.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
            pagination: {
                filtered: filtered.length, // filtered is what the table shows at the bottom (page count is computed based on this). It may be equal to total if no filters are applied, that's ok
                total: users.length, // [OPTIONAL] if couchdb returns this, we may want to display it somewhere (currently not used)
            },
        };
    },
};

export default userService;
