import moment from 'moment';
import 'moment/min/locales.min'; //var localLocale = moment();localLocale.locale('fr');

var langT = key => key;

const dateUtils = {
    serverFormat: 'YYYY-MM-DDTHH:mm:ss',
    timeFormat: 'h:mm:ss A',
    shortTimeFormat: 'h:mm A',
    monthFormat: 'MMMM YYYY',
    locale: 'en',

    formatElapsedTime: (elapsed, elapsedUnit = 'minutes') => {
        let days, hours, minutes;

        switch (elapsedUnit) {
            case 'minutes':
            case 'minute': {
                days = Math.floor(elapsed / 1440);
                hours = Math.floor((elapsed % 1440) / 60);
                minutes = elapsed % 60;
                break;
            }
            case 'hours':
            case 'hour': { // highly unlikely we'll ever use this, but serves as model
                days = Math.floor(elapsed / 24);
                hours = Math.floor(elapsed % 24);
                minutes = Math.round((elapsed - Math.floor(elapsed)) * 60);
                break;
            }
            // 'days' case is highly unlikely if ever used (elapsed would be like 2.54 days from which to infer number of hours/minutes)
            default: break;
        }

        let formatted = [];

        days && formatted.push([days, days == 1 ? langT('day') : langT('days')].join(' '));
        hours && formatted.push([hours, hours == 1 ? langT('hour') : langT('hours')].join(' '));
        minutes && formatted.push([minutes, minutes == 1 ? langT('minute') : langT('minutes')].join(' '));

        return formatted.join(', ');
    },

    today: () => {
        let d = new Date();
        return new Date(d.getFullYear(), d.getMonth(), d.getDate());
    },

    tomorrow: () => {
        let tomorrow = dateUtils.today();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    },

    yesterday: () => {
        let yesterday = dateUtils.today();
        yesterday.setDate(yesterday.getDate() - 1);
        return yesterday;
    },

    toServerString: momentDate => dateUtils.toLocalMomentFromString(moment(momentDate)).format(dateUtils.serverFormat),

    toShortTimeString: stringDate => !!stringDate && moment(stringDate).format(`${dateUtils.shortTimeFormat}`) || stringDate,

    toApiString: stringDate => moment(stringDate).format('YYYYMMDD'),

    getDateFromString: stringDate => stringDate && stringDate.length > 10 && stringDate.substr(0, 10) || '',

    getTimeFromString: stringDate => stringDate && stringDate.length > 10 && stringDate.substr(11) || '',

    fromServerString: stringDate => moment(stringDate, dateUtils.serverFormat),

    addDays: (date, daysNr) => moment(date).add(daysNr, 'days'),

    addWeeks: (date, weeksNr) => moment(date).add(weeksNr, 'weeks'),

    addMonths: (date, monthsNr) => moment(date).add(monthsNr, 'months'),

    addYears: (date, yearsNr) => moment(date).add(yearsNr, 'years'),

    startOfDay: date => dateUtils.toServerString(moment(date).startOf('day')),

    endOfDay: date => dateUtils.toServerString(moment(date).endOf('day')),

    startOfWeek: date => dateUtils.toServerString(moment(date).startOf('week')),

    startOfMonth: date => dateUtils.toServerString(moment(date).startOf('month')),

    startOfYear: date => dateUtils.toServerString(moment(date).startOf('year')),

    endOfWeek: date => dateUtils.toServerString(moment(date).endOf('week')),

    lastWeekStartDate: () => moment().subtract(1, 'weeks').startOf('isoWeek'),

    lastWeekEndDate: () => moment().subtract(1, 'weeks').endOf('isoWeek'),

    navigatePrevious: (date, amount, unit) => dateUtils.toServerString(moment(date).startOf(unit).subtract(amount, unit)),

    navigateNext: (date, amount, unit) => dateUtils.toServerString(moment(date).startOf(unit).add(amount, unit)),

    toMoment: date => moment(date),

    toTimeString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format(dateUtils.timeFormat);
    },
    toDateString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format('dddd, MMMM D, YYYY');
    },
    toShortDateString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format('MMMM D, YYYY');
    },
    toISODateString: isoStringDate => { // assumes a serverFormat string
        if (!isoStringDate || !isoStringDate.substr) { return isoStringDate; }
        return isoStringDate.substr(0, 10);
    },
    toShortMonthDateString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format('MMM D, YYYY');
    },
    toDateTimeString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format(`MMMM D, YYYY, ${dateUtils.timeFormat}`);
    },
    toExtendedDateTimeString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format(`dddd, MMMM D, YYYY, ${dateUtils.shortTimeFormat}`);
    },
    toShortDateTimeString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format(`MMM D, YYYY, ${dateUtils.shortTimeFormat}`);
    },
    toMonthString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format(dateUtils.monthFormat);
    },

    toMonthDayString: stringDate => {
        if (!stringDate) { return stringDate; }
        return dateUtils.toLocalMomentFromString(stringDate)
            .format('MMMM Do');
    },

    toScheduleDateString: date => {
        return dateUtils.toLocalMomentFromString(date)
            .format(`dddd, MMMM D`);
    },

    toScheduleDateTimeString: date => {
        return dateUtils.toLocalMomentFromString(date)
            .format(`dddd, MMMM D, ${dateUtils.shortTimeFormat}`);
    },

    toScheduleTimeString: date => {
        return dateUtils.toLocalMomentFromString(date)
            .format(`${dateUtils.shortTimeFormat}`);
    },

    toScheduleDifferenceFromDateTime: (date, base) => {
        let m1 = dateUtils.toLocalMomentFromString(date),
            m2 = dateUtils.toLocalMomentFromString(base),
            include = {
                year: m1.year() != m2.year(),
            };
        include.month = include.year || (m1.month() != m2.month());
        include.day = include.month || (m1.date() != m2.date());
        include.month = include.month || include.day;
        include.time = true;
        let format = (include.day ? 'dddd, ' : '')
            + (include.month ? 'MMMM ' : '')
            + (include.day ? 'D' : '')
            + (include.time ? (include.day ? ', ' : '') + dateUtils.shortTimeFormat : '');
        return m1.format(format);
    },

    toWeekIntervalString: stringDate => {
        if (!stringDate) { return stringDate; }
        let m1 = dateUtils.toLocalMomentFromString(stringDate),
            m2 = moment(m1).add(7, 'days'),
            include = {
                year: m1.year() != m2.year(),
                month: m1.year() != m2.year() || m1.month() != m2.month(),
            },
            formatM1 = 'MMM D',
            formatM2 = include.month ? 'MMM D' : 'D';
        if (include.year) {
            formatM1 += ', YYYY';
            formatM2 += ', YYYY';
        }
        return m1.format(formatM1) + ' - ' + m2.format(formatM2) + (include.year ? '' : ', ' + m1.format('YYYY'));
    },

    getDaysInMonth: date => moment(date).daysInMonth(),

    getYearsFromServerString: stringDate => moment().diff(moment(stringDate, dateUtils.serverFormat), 'years'),

    enumerateDaysBetweenDates: (startDate, endDate) => {
        let dates = [],
            currDate = moment(startDate).startOf('day'),
            lastDate = moment(endDate).startOf('day');
        do {
            dates.push(currDate.clone());
        } while (currDate.add(1, 'days').diff(lastDate) <= 0);
        return dates;
    },

    toFormEditString: momentDate => {
        return momentDate.format('MM/DD/YYYY');
    },

    minDate: () => moment.utc('0001-01-01'),

    toDateInterval: (startDate, endDate, isShortFormat = true, compareToNow = false, hideTimeInDate = false) => {
        const now = moment().toObject(),
            start = moment(startDate).toObject(),
            end = moment(endDate).toObject();
        let isStartNow = compareToNow && (start.date == now.date && start.months == now.months && start.years == now.years);
        let isEndNow = compareToNow && (end.date == now.date && end.months == now.months && end.years == now.years);
        let isSameDate = start.date == end.date && start.months == end.months && start.years == end.years;
        if (hideTimeInDate) {
            if (isSameDate) {
                return {
                    start: dateUtils.toDateString(startDate),
                }
            }
            else {
                return {
                    start: dateUtils.toDateString(startDate),
                    end: dateUtils.toDateString(endDate),
                }
            }
        }
        else {
            return {
                start: dateUtils[(isSameDate && isStartNow) ? 'toShortTimeString' : (isShortFormat ? 'toShortDateTimeString' : 'toDateTimeString')](startDate),
                end: dateUtils[(isSameDate && isEndNow) ? 'toShortTimeString' : (isSameDate ? 'toShortTimeString' : (isShortFormat ? 'toShortDateTimeString' : 'toDateTimeString'))](endDate),
            }
        }
    },

    toMonthFormat: momentDate => dateUtils.toLocalMoment(momentDate).format(`${dateUtils.monthFormat}`),

    firstDayOfWeek: momentDate => momentDate.locale(dateUtils.locale).localeData().firstDayOfWeek(),

    toLocalMomentFromString: stringDate => {
        var localLocale = moment(stringDate);
        localLocale.locale(dateUtils.locale);
        return localLocale;
    },
    toLocalMoment: momentDate => {
        var localLocale = momentDate.clone();
        localLocale.locale(dateUtils.locale);
        return localLocale;
    },

    processNullDates: (obj, ...fields) => {
        fields.map(field => {
            if (obj[field] == nullDate) {
                obj[field] = null;
            }
        });
    },

    getHMS: (momentDate = moment()) => ({
        h: momentDate.hour(),
        m: momentDate.minute(),
        s: momentDate.second(),
    }),

    setHMS: (momentDate = moment(), h, m, s) => momentDate.hour(h).minute(m).second(s),
};

export const nullDate = dateUtils.toServerString(dateUtils.minDate());

export default dateUtils;
