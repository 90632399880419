import React from 'react';
import { Grid, Typography, Checkbox, MenuItem, FormControlLabel } from '@mui/material';
import { TextField, Select, DatePicker, Switches } from 'mui-rff';
import { Field } from 'react-final-form';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';
import { UserType } from '../../../_core/services/subscriptionsService';

export default function SubscriptionForm({ ...props }) {
    // console.log('Content props', props);
    return <Grid container spacing={3} sx={{ px: 2, pt: 1, pb: 3 }}>
        <Grid item container xs={12}>
            <Field name="_id" component={({ input: { value } }) => value ? <Typography variant="caption" color="text.disabled">{`ID: ${value}`}</Typography> : null} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <Select name="userType" label="User Type">
                {getEnumItemsAsArray(UserType).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
            <DatePicker name="endDate" label="Ends" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField name="subscriptionLimitId" label="Limit ID"/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <Switches
                name="isActive"
                data={{ label: 'Is Active' }}
            />
        </Grid>
    </Grid>;
};
