import React from 'react';
import { useLocation, useRoutes } from 'react-router';
import PageTransition from '../../components/pageTransition';
import { useUserProfile } from '../contexts/userProfileContext';
import { buildRoutesObjectForExport } from './utils';
import { EmptyIndex, NoMatch, PrivateRoute } from './components';

// import public pages here
import Landing from '../../pages/landing';

// import private pages here
import HomePage from '../../pages/home/homePage';
import FeedbackPage from '../../pages/people/feedback/feedbackPage';
import UsersPage from '../../pages/people/users/usersPage';
import SubscriptionsPage from '../../pages/people/subscriptions/subscriptionsPage';
import AboutPage from '../../pages/about/aboutPage';
import AccountPage from '../../pages/account/accountPage';
import ProspectsPage from '../../pages/people/prospects/prospectsPage';

export const publicRoutesDefinition = [{
    key: 'landing', // this will become publicRoutes.landing = '/'
    path: '/',
    element: <Landing />
}, {
    path: '*',
    element: <NoMatch isPublic />,
}];

export const publicRoutes = buildRoutesObjectForExport(publicRoutesDefinition);
// console.warn('publicRoutes', publicRoutes);

export const privateRoutesDefinition = [{
    key: 'home', // this will become privateRoutes.home = '/'
    path: '/',
    element: <HomePage />,
    breadcrumb: 'Overview',
}, {
    key: 'about', // this will become privateRoutes.about = '/about'
    path: '/about',
    element: <AboutPage />,
}, {
    key: 'account', // this will become privateRoutes.account = '/account'
    path: '/account',
    element: <AccountPage />,
}, {
    key: 'people', // this will become privateRoutes.people.index = '/people' (has .index b/c it has children)
    path: '/people',
    children: [{
        index: true, // yes, no "key" on the index route
        element: <EmptyIndex path="/people" />,
    }, {
        key: 'subscriptions', // this will become privateRoutes.people.feedback = '/people/feedback'
        path: 'subscriptions',
        element: <SubscriptionsPage />,
    }, {
        key: 'prospects', 
        path: 'prospects',
        element: <ProspectsPage />,
    }, {
        key: 'feedback', // this will become privateRoutes.people.feedback = '/people/feedback'
        path: 'feedback',
        element: <FeedbackPage />,
    }, {
        key: 'users', // this will become privateRoutes.people.users = '/people/users'
        path: 'users',
        element: <UsersPage />,
    }, {
        path: '*',
        element: <NoMatch />,
    }],
}
// , {
//     key: 'private',
//     path: '/private',
//     children: [{
//         index: true,
//         element: <EmptyIndex path="/private" />,
//     }, {
//         key: 'allow',
//         path: 'allow',
//         element: <PrivateRoute>
//             <EmptyIndex />
//         </PrivateRoute>,
//     }, {
//         key: 'deny',
//         path: 'deny',
//         element: <PrivateRoute permissions={['can also be', 'a single permission', 'without array wrapper']}>
//             <EmptyIndex />
//         </PrivateRoute>,
//     }],
// }
];

export const privateRoutes = buildRoutesObjectForExport(privateRoutesDefinition);
// console.warn('privateRoutes', privateRoutes);

export default () => {
    const location = useLocation();
    const isLoggedIn = useUserProfile();

    const element = useRoutes(isLoggedIn ? privateRoutesDefinition : publicRoutesDefinition);

    return <PageTransition location={location}>
        {element}
    </PageTransition>;
};

