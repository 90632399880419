export default {
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    popup: {
        position: 'relative',
        width: '96%',
        mt: '8vh',
        maxWidth: '960px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    content: {
        maxHeight: '70vh',
        overflow: 'auto',
    },
    button: {
        minWidth: '48px',
    },
};
