import React, { useEffect } from 'react';
import { Field } from 'react-final-form';
import { TextField, Select, DatePicker, D, Switches } from 'mui-rff';
import { Grid, MenuItem } from '@mui/material';
import { ProspectPaymentSource, ProspectSource, UserType } from '../../../_core/services/subscriptionsService';
import { getEnumItemsAsArray } from '../../../components/lcTable/filters/utils';

export default function ProspectsFilterForm({ ...props }) {
    // useEffect(() => {
    console.log('form props', props);
    // }, []);

    return <>
        <Grid item xs={6}>
            <TextField name="email" label="Email" />
        </Grid>
        <Grid item xs={6}>
            <TextField name="name" label="Name" />
        </Grid>
        <Grid item xs={6}>
            <Select name="userType" label="User Type">
                {getEnumItemsAsArray(UserType).map((item, index) => <MenuItem key={index} value={item}>{item}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={6}>
            <TextField name="programme" label="Programme" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.after" label="Registered After" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <DatePicker name="createdAt.before" label="Registered Before" inputFormat="yyyy-MM-dd" mask="____-__-__" />
        </Grid>
        <Grid item xs={6}>
            <TextField name="userId" label="userId" />
        </Grid>
    </>;
};
