import { FilterType } from './constants';

export const buildMangoQuery = (filters = []) => {
    const query = {
        selector: {},
    };

    (Array.isArray(filters) ? filters : []).map(
        ({ field, type, value, options }) => {
            switch (type) {
                case FilterType.Boolean:
                    query.selector[field] = value;
                    break;
                case FilterType.Text:
                    // TODO: improve this with `options` (startsWith, endsWith, contains - use $regex)
                    query.selector[field] = value;
                    break;
                case FilterType.Select:
                    if (!Array.isArray(value)) { value = [value]; }
                    query.selector[field] = {
                        '$in': value,
                    };
                    break;
                default:
                    logger.warn('[buildMangoQuery] skipping field', field, type, value, options);
                    break;
            }
        }
    );

    return query;
};

export const getEnumItemsAsArray = (object = {}) => {
    return Object.keys(object).map(key => object[key]);
}